define("discourse/plugins/discourse-global-communities/discourse/initializers/replace-icons", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-replace-icon",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        api.replaceIcon("bookmark", "bookmark-squid");
        api.replaceIcon("d-muted", "far-bell-slash");
        api.replaceIcon("d-regular", "far-bell");
        api.replaceIcon("d-tracking", "bell");
        api.replaceIcon("d-watching-first", "discourse-bell-exclamation");
        api.replaceIcon("d-watching", "discourse-bell-exclamation");
        api.replaceIcon("share", "share-squid");
        api.replaceIcon("far-bell", "bell-squid");
        api.replaceIcon("file", "article-squid");
        api.replaceIcon("file-lines", "article-squid");
        api.replaceIcon("caret-down", "chevron_down-squid");
        api.replaceIcon("caret-up", "chevron_up-squid");
        api.replaceIcon("comment", "comment-squid");
        api.replaceIcon("message", "comment-squid");
        api.replaceIcon("far-comment", "comment-squid");
        api.replaceIcon("chevron-down", "chevron_down-squid");
        api.replaceIcon("chevron-left", "chevron_left-squid");
        api.replaceIcon("chevron-right", "chevron_right-squid");
        api.replaceIcon("chevron-up", "chevron_up-squid");
        api.replaceIcon("clock", "clock-squid");
        api.replaceIcon("xmark", "close_large-squid");
        api.replaceIcon("circle-check", "confirm-squid");
        api.replaceIcon("calendar-days", "date-squid");
        api.replaceIcon("heart", "like_off-squid");
        api.replaceIcon("d-liked", "like_on-squid");
        api.replaceIcon("d-unliked", "like_off-squid");
        api.replaceIcon("list", "list-squid");
        api.replaceIcon("list-ul", "list-squid");
        api.replaceIcon("discourse-expand", "maximise-squid");
        api.replaceIcon("user", "member-squid");
        api.replaceIcon("ellipsis", "menu_meatball-squid");
        api.replaceIcon("compress", "minimise-squid");
        api.replaceIcon("discourse-compress", "minimise-squid");
        api.replaceIcon("play", "play-squid");
        api.replaceIcon("circle-plus", "plus-squid");
        api.replaceIcon("magnifying-glass", "search-squid");
        api.replaceIcon("up-down", "switch-squid");
        api.replaceIcon("link", "link-squid");
        api.replaceIcon("far-trash-can", "trash-squid");
        api.replaceIcon("flag", "flag-squid");
        api.replaceIcon("reply", "reply-squid");
        api.replaceIcon("pencil", "pencil-squid");
        api.replaceIcon("far-calendar-days", "register-squid");
        api.replaceIcon("follow_off-squid", "d-regular-squid");
        api.replaceIcon("discourse-bookmark-clock", "clock-squid");
      });
    }
  };
});
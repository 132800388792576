define("discourse/plugins/discourse-global-communities/discourse/feature-flags/deprioritize-topics", ["exports", "discourse/plugins/discourse-global-communities/discourse/components/modal/deprioritize-topic"], function (_exports, _deprioritizeTopic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DeprioritizeTopics {
    static enabledSetting = "deprioritize_topics_enabled";
    static afterEnabled(api) {
      const modalService = api.container.lookup("service:modal");
      api.addTopicAdminMenuButton(topic => {
        if (topic.isPrivateMessage) {
          return;
        }
        return {
          action: () => {
            modalService.show(_deprioritizeTopic.default, {
              model: topic
            });
          },
          icon: "shield-halved",
          className: "btn-default agc-deprioritize-topic",
          label: "agc.topic_visibility"
        };
      });
    }
  }
  _exports.default = DeprioritizeTopics;
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/tv-episodes", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/tv-episode", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _discourseI18n, _tvEpisode, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcPostMenuTvEpisodes extends _component.default {
    static shouldRender(args) {
      return args.post.firstPost && args.post.episodes?.length > 0;
    }
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    get episodes() {
      return this.args.outletArgs.post.episodes.map(episode => {
        return this.store.createRecord("tv-episode", episode);
      });
    }
    get sortedEpisodes() {
      return this.episodes.sort((a, b) => {
        let titleA = a.title.toUpperCase();
        let titleB = b.title.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "sortedEpisodes", [_tracking.cached]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <h4 class="episodes">
          {{i18n "agc.tv.episodes"}}
        </h4>
        {{#each this.sortedEpisodes key="id" as |episode|}}
          <AgcTvEpisode @episode={{episode}} @renderExcerpt={{true}} />
        {{/each}}
      
    */
    {
      "id": "UM1Zp059",
      "block": "[[[1,\"\\n    \"],[10,\"h4\"],[14,0,\"episodes\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"agc.tv.episodes\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sortedEpisodes\"]]],null]],null],\"id\",[[[1,\"      \"],[8,[32,1],null,[[\"@episode\",\"@renderExcerpt\"],[[30,1],true]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"]],[\"episode\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/tv-episodes.js",
      "scope": () => [_discourseI18n.i18n, _tvEpisode.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcPostMenuTvEpisodes;
});
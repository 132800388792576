define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/full-page-search-above-search-header/improved-search", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.displayImprovedSearch}}
    <AgcImprovedSearch @outletArgs={{@outletArgs}} />
  {{/if}}
  */
  {
    "id": "4Q787APu",
    "block": "[[[41,[30,0,[\"displayImprovedSearch\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@outletArgs\"],[[30,1]]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"agc-improved-search\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/full-page-search-above-search-header/improved-search.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-messages-above-navigation/agc-activity-nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-activity-archived-messages user-secondary-navigation">
    <Agc::UserProfile::ActivityNav @model={{@model}} />
  </div>
  <div class="messages-disabled-notice">
    <div class="row">
      <div class="alert alert-info {{this.notice.id}}">
        {{html-safe
          (i18n "agc.user.messages_disabled_notice" chatUrl=(get-url "/chat"))
        }}
      </div>
    </div>
  </div>
  */
  {
    "id": "SVg+aPtu",
    "block": "[[[10,0],[14,0,\"agc-activity-archived-messages user-secondary-navigation\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"messages-disabled-notice\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"alert alert-info \",[30,0,[\"notice\",\"id\"]]]]],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,3],[\"agc.user.messages_disabled_notice\"],[[\"chatUrl\"],[[28,[37,4],[\"/chat\"],null]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@model\"],false,[\"div\",\"agc/user-profile/activity-nav\",\"html-safe\",\"i18n\",\"get-url\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-messages-above-navigation/agc-activity-nav.hbs",
    "isStrictMode": false
  });
});
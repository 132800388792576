define("discourse/plugins/discourse-global-communities/discourse/config/app-events-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TAB_CONTENTS_ERROR = _exports.TAB_CONTENTS = _exports.SEARCH_LOAD_MORE = _exports.FILTERS_POST_TAB = _exports.FEED_SORT_UPDATE = _exports.ESCALATION_MODAL_CLOSE = void 0;
  /**
   * Sends search results from specific search tab to parent tab container for caching.
   *
   * - tab: tab that results are coming from
   * - action: 'results'
   * - value: results array
   * - pageNumber: how many pages have loaded, starting at 0
   * - hasReachedLastPage
   */
  const TAB_CONTENTS = _exports.TAB_CONTENTS = "amzn:tab-content:action";

  /**
   * Sent to a specific search tab when an error is detected.
   *
   * - error: the error returned by the Discourse API request.
   */
  const TAB_CONTENTS_ERROR = _exports.TAB_CONTENTS_ERROR = "amzn:tab-content:error";

  /**
   * Sent to specific search tab to load more results for infinite scrolling.
   *
   * - tab: tab to load more results
   */
  const SEARCH_LOAD_MORE = _exports.SEARCH_LOAD_MORE = "amzn:search-load-more:action";

  /**
   * Sent filters values to Posts-tab to make new query.
   *
   * - queryString: string with all filters concatenated.
   */
  const FILTERS_POST_TAB = _exports.FILTERS_POST_TAB = "amzn:posts-search-filters:action";

  /**
   * Triggered when user tries to close escalation modal.
   */
  const ESCALATION_MODAL_CLOSE = _exports.ESCALATION_MODAL_CLOSE = "amzn:escalation:modal-close";

  /**
   * Triggered when a user updates their default feed sort for updating the
   * feed switcher toggle
   *
   * - eventId: id of the sorting method the user chose
   */
  const FEED_SORT_UPDATE = _exports.FEED_SORT_UPDATE = "amzn:feed:sort-update";
});
define("discourse/plugins/discourse-global-communities/discourse/helpers/analytics/improvedSearchAnalyticsAnnotator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.triggerFilterClickAnalytics = triggerFilterClickAnalytics;
  _exports.triggerFilterStateAnalytics = triggerFilterStateAnalytics;
  _exports.triggerNoSearchResultsAnalytics = triggerNoSearchResultsAnalytics;
  _exports.triggerPaginationAnalytics = triggerPaginationAnalytics;
  _exports.triggerSearchErrorAnalytics = triggerSearchErrorAnalytics;
  _exports.triggerTabToggleAnalytics = triggerTabToggleAnalytics;
  function triggerFilterClickAnalytics(appEvents, eventData) {
    appEvents.trigger("agc-custom:improved_search_filter_click", eventData);
  }
  function triggerFilterStateAnalytics(appEvents, eventData) {
    appEvents.trigger("agc-custom:improved_search_active_filters", eventData);
  }
  function triggerNoSearchResultsAnalytics(appEvents, eventData) {
    appEvents.trigger("agc-custom:improved_search_no_results", eventData);
  }
  function triggerPaginationAnalytics(appEvents, eventData) {
    appEvents.trigger("agc-custom:improved_search_pagination", eventData);
  }
  function triggerSearchErrorAnalytics(appEvents, eventData) {
    appEvents.trigger("agc-custom:improved_search_error", eventData);
  }
  function triggerTabToggleAnalytics(appEvents, eventData) {
    appEvents.trigger("agc-custom:improved_search_active_tab", eventData);
  }
});
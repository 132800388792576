define("discourse/plugins/discourse-global-communities/discourse/config/advanced-search-filters-config", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.responseTypeOptions = _exports.USER_PREFIX = _exports.START_DATE_PREFIX = _exports.SORT_PREFIX = _exports.SORT_BY_OPTIONS = _exports.EXPERT_QUESTION_PREFIX = _exports.END_DATE_PREFIX = _exports.CATEGORY_PREFIX = _exports.ATTACHMENT_TYPE_OPTIONS = _exports.ATTACHMENT_PREFIX = _exports.ANALYTICS_EVENTS = void 0;
  const SORT_PREFIX = _exports.SORT_PREFIX = "order:";
  const CATEGORY_PREFIX = _exports.CATEGORY_PREFIX = "#";
  const USER_PREFIX = _exports.USER_PREFIX = "@";
  const ATTACHMENT_PREFIX = _exports.ATTACHMENT_PREFIX = "with:";
  const EXPERT_QUESTION_PREFIX = _exports.EXPERT_QUESTION_PREFIX = "is:";
  const START_DATE_PREFIX = _exports.START_DATE_PREFIX = "after:";
  const END_DATE_PREFIX = _exports.END_DATE_PREFIX = "before:";
  const ANALYTICS_EVENTS = _exports.ANALYTICS_EVENTS = {
    filterToggle: "filter_toggle",
    resetFilters: "reset_filters"
  };

  /**
   *
   * Config values to populate dropdown Sort by on advance search page.
   *
   * interface SortByOptions {
   *   id: number;
   *   name: string; name displayed on the dropdown.
   *   term: string; term used for filtering by the /search API.
   * }
   *
   */

  const SORT_BY_OPTIONS = _exports.SORT_BY_OPTIONS = [{
    name: _I18n.default.t("agc.search-tabs.filters.latest-reply"),
    id: 1,
    term: "latest",
    prefix: SORT_PREFIX
  }, {
    name: _I18n.default.t("agc.search-tabs.filters.most-liked"),
    id: 2,
    term: "likes",
    prefix: SORT_PREFIX
  }, {
    name: _I18n.default.t("agc.search-tabs.filters.most-viewed"),
    id: 3,
    term: "views",
    prefix: SORT_PREFIX
  }, {
    name: _I18n.default.t("agc.search-tabs.filters.latest-post"),
    id: 4,
    term: "latest_topic",
    prefix: SORT_PREFIX
  }];
  const responseTypeOptions = partnerName => {
    return [{
      name: _I18n.default.t("agc.search-tabs.filters.amazon-response"),
      id: 1,
      term: "amazon_response",
      prefix: ATTACHMENT_PREFIX
    }, {
      name: _I18n.default.t("agc.search-tabs.filters.partner-response", {
        partnerName
      }),
      id: 2,
      term: "guide_response",
      prefix: ATTACHMENT_PREFIX
    }, {
      name: _I18n.default.t("agc.search-tabs.filters.question-for-partner", {
        partnerName
      }),
      id: 3,
      term: "category_expert_question",
      prefix: EXPERT_QUESTION_PREFIX
    }];
  };
  _exports.responseTypeOptions = responseTypeOptions;
  const ATTACHMENT_TYPE_OPTIONS = _exports.ATTACHMENT_TYPE_OPTIONS = [{
    name: _I18n.default.t("agc.search-tabs.filters.video"),
    id: 1,
    term: "video",
    prefix: ATTACHMENT_PREFIX
  }, {
    name: _I18n.default.t("agc.search-tabs.filters.photo"),
    id: 2,
    term: "photo",
    prefix: ATTACHMENT_PREFIX
  }, {
    name: _I18n.default.t("agc.search-tabs.filters.document"),
    id: 3,
    term: "attachment",
    prefix: ATTACHMENT_PREFIX
  }];
});
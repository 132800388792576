define("discourse/plugins/discourse-global-communities/discourse/components/members-tab", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/helpers/improved-search-helpers", "discourse/plugins/discourse-global-communities/discourse/services/getMembers", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _appEventsConfig, _improvedSearchHelpers, _getMembers2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Need div .directory-wrapper and .directory-body
  because discourse uses them to style the member cards }}
  
  {{! TODO: Bring back removed tests once `Agc::MemberButton` is moved to a plugin
  <Agc::MemberButton> found here: https://github.com/discourse/agc-base-component/blob/main/javascripts/discourse/components/agc/member-button.js
  SIM: https://sim.amazon.com/issues/AGC-5637}}
  
  <div class="directory-wrapper">
    <div class="directory-body" {{did-update this.getNewMembers @searchTerm}}>
      {{#each this.members as |member|}}
        <Agc::MemberButton @member={{member}}>
          {{#if this.siteSettings.enable_category_experts}}
            <EndorsementButton @user={{member}} @location="member-directory" />
          {{/if}}
          {{#if member.has_chat_enabled}}
            <div class="chat-btn-wrapper" data-username={{member.username}}>
              <Chat::DirectMessageButton @user={{member}} />
            </div>
          {{/if}}
        </Agc::MemberButton>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "BWqv27+z",
    "block": "[[[1,\"\\n\"],[1,\"\\n\"],[10,0],[14,0,\"directory-wrapper\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"directory-body\"],[4,[38,1],[[30,0,[\"getNewMembers\"]],[30,1]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"members\"]]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@member\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"enable_category_experts\"]],[[[1,\"          \"],[8,[39,6],null,[[\"@user\",\"@location\"],[[30,2],\"member-directory\"]],null],[1,\"\\n\"]],[]],null],[41,[30,2,[\"has_chat_enabled\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-btn-wrapper\"],[15,\"data-username\",[30,2,[\"username\"]]],[12],[1,\"\\n            \"],[8,[39,7],null,[[\"@user\"],[[30,2]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@searchTerm\",\"member\"],false,[\"div\",\"did-update\",\"each\",\"-track-array\",\"agc/member-button\",\"if\",\"endorsement-button\",\"chat/direct-message-button\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/members-tab.hbs",
    "isStrictMode": false
  });
  class MembersTab extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "members", [_tracking.tracked], function () {
      return [];
    }))();
    #members = (() => (dt7948.i(this, "members"), void 0))();
    tabName;
    pageNumber;
    hasReachedLastPage;
    constructor(owner, args) {
      super(owner, args);
      this.tabName = args.tabName;
      const resultsCache = args.resultsCache;
      this.pageNumber = resultsCache.pageNumber;
      this.hasReachedLastPage = resultsCache.hasReachedLastPage;
      if (resultsCache.results) {
        this.members = resultsCache.results;
      } else {
        this.getMembers();
      }
      this.appEvents.on(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    getNewMembers() {
      this.members = [];
      this.pageNumber = 0;
      this.hasReachedLastPage = false;
      this.getMembers();
    }
    static #_5 = (() => dt7948.n(this.prototype, "getNewMembers", [_object.action]))();
    getMembers() {
      const fetchParams = {
        searchTerm: this.search.activeGlobalSearchTerm,
        pageNumber: this.pageNumber
      };
      const startRequest = Date.now();
      (0, _getMembers2.fetchMembers)(fetchParams).then(result => {
        const endRequest = Date.now();
        const requestLatency = endRequest - startRequest;
        (0, _improvedSearchHelpers.sendRequestLatencyToRum)(requestLatency, this.tabName);
        this.members = [...this.members, ...result.members];
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS, {
          action: "results",
          value: this.members,
          tab: this.tabName,
          pageNumber: this.pageNumber,
          hasReachedLastPage: result?.members.length < this.siteSettings.improved_search_page_size,
          extractAnalyticsKeys: member => member.id
        });
      }).catch(e => {
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS_ERROR, {
          error: e,
          tab: this.tabName
        });
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "getMembers", [_object.action]))();
    getNextPage(args) {
      if (args.tab === this.tabName) {
        this.pageNumber++;
        this.getMembers();
      }
    }
  }
  _exports.default = MembersTab;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MembersTab);
});
define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/below-user-summary-stats/gc-circle-user-summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="circles-section-wrapper">
    <GcCirclesSection
      @model={{this.model}}
      @title={{i18n "discourse_circles.user.circles"}}
      @onViewAll={{action "viewAll"}}
    >
      <:empty>
        {{#if this.model.circles_current_user_summary}}
          {{html-safe (i18n "agc.circles.user_profile.empty.current_user")}}
          <LinkTo class="circles-discover" @route="circles">
            {{i18n "agc.circles.discover_circles"}}
          </LinkTo>
        {{else}}
          {{html-safe (i18n "agc.circles.user_profile.empty.other_user")}}
        {{/if}}
      </:empty>
    </GcCirclesSection>
    {{#if
      (not
        (and
          this.model.circles_current_user_summary (not this.model.circles.content)
        )
      )
    }}
      <LinkTo
        class="circles-discover-more"
        @route="circles"
        @query={{hash type="not_member"}}
      >
        {{i18n "agc.circles.discover_more"}}
      </LinkTo>
    {{/if}}
  </div>
  */
  {
    "id": "22w4adac",
    "block": "[[[10,0],[14,0,\"circles-section-wrapper\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@model\",\"@title\",\"@onViewAll\"],[[30,0,[\"model\"]],[28,[37,2],[\"discourse_circles.user.circles\"],null],[28,[37,3],[[30,0],\"viewAll\"],null]]],[[\"empty\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"circles_current_user_summary\"]],[[[1,\"        \"],[1,[28,[35,6],[[28,[37,2],[\"agc.circles.user_profile.empty.current_user\"],null]],null]],[1,\"\\n        \"],[8,[39,7],[[24,0,\"circles-discover\"]],[[\"@route\"],[\"circles\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,2],[\"agc.circles.discover_circles\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,6],[[28,[37,2],[\"agc.circles.user_profile.empty.other_user\"],null]],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"],[41,[28,[37,8],[[28,[37,9],[[30,0,[\"model\",\"circles_current_user_summary\"]],[28,[37,8],[[30,0,[\"model\",\"circles\",\"content\"]]],null]],null]],null],[[[1,\"    \"],[8,[39,7],[[24,0,\"circles-discover-more\"]],[[\"@route\",\"@query\"],[\"circles\",[28,[37,10],null,[[\"type\"],[\"not_member\"]]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"agc.circles.discover_more\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"gc-circles-section\",\"i18n\",\"action\",\":empty\",\"if\",\"html-safe\",\"link-to\",\"not\",\"and\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/below-user-summary-stats/gc-circle-user-summary.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/notify-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/notification-levels", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _concatClass, _notificationLevels, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WATCHING_LEVEL = _notificationLevels.topicLevels.find(tl => tl.key === "watching")?.id;
  class AgcPostMenuNotifyButton extends _component.default {
    static shouldRender(args, context) {
      return args.post.post_number === 1 && args.post.user?.id !== context.currentUser?.id;
    }
    static #_ = (() => dt7948.g(this.prototype, "agcTopicListWatching", [_service.service]))();
    #agcTopicListWatching = (() => (dt7948.i(this, "agcTopicListWatching"), void 0))();
    get watching() {
      return this.args.post.topicNotificationLevel === WATCHING_LEVEL;
    }
    toggleWatching() {
      this.agcTopicListWatching.toggleWatching(this.args.post.topic);
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleWatching", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class={{concatClass
            "toggle-watching-btn"
            (if this.watching "-watching" "-regular")
          }}
          ...attributes
          @action={{this.toggleWatching}}
          @icon={{if this.watching "d-watching" "d-regular"}}
          @label={{if
            this.watching
            "notification_levels.notified"
            "notification_levels.notify"
          }}
          @title={{if
            this.watching
            "notification_levels.notified"
            "notification_levels.notify"
          }}
        />
      
    */
    {
      "id": "nR573uPi",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"toggle-watching-btn\",[52,[30,0,[\"watching\"]],\"-watching\",\"-regular\"]],null]],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"toggleWatching\"]],[52,[30,0,[\"watching\"]],\"d-watching\",\"d-regular\"],[52,[30,0,[\"watching\"]],\"notification_levels.notified\",\"notification_levels.notify\"],[52,[30,0,[\"watching\"]],\"notification_levels.notified\",\"notification_levels.notify\"]]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/notify-button.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcPostMenuNotifyButton;
});
define("discourse/plugins/discourse-global-communities/discourse/initializers/tv", ["exports", "@ember/runloop", "virtual-dom", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/tv-episodes", "discourse/plugins/discourse-global-communities/discourse/helpers/update-episode-watched-timestamp", "discourse/plugins/discourse-global-communities/discourse/helpers/videojs-initializer"], function (_exports, _runloop, _virtualDom, _pluginApi, _tvEpisodes, _updateEpisodeWatchedTimestamp, _videojsInitializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "tv",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      (0, _pluginApi.withPluginApi)("1.39.0", api => {
        let lastTopMetadataValue;
        const resizeDsptvContainer = function () {
          const topicOwnerDiv = document.querySelector(".topic-owner-tv-episode");
          if (!topicOwnerDiv) {
            return;
          }
          const tvContainer = document.querySelector(".tv-connector");
          if (!tvContainer) {
            return;
          }
          tvContainer.classList.add("has-content");
          const postStream = document.querySelector(".post-stream");
          if (!postStream) {
            return;
          }
          tvContainer.style.width = `${postStream.getBoundingClientRect().width}px`;
          const topicTitle = document.querySelector("#topic-title");
          if (topicTitle) {
            topicTitle.style.width = `${tvContainer.getBoundingClientRect().width - parseFloat(getComputedStyle(topicTitle).fontSize) - 2}px`;
            const titleWrapper = topicTitle.querySelector(".title-wrapper");
            const topicTitleHeight = titleWrapper.getBoundingClientRect().height;
            const topicMetadata = document.querySelector(".topic-meta-data");
            if (topicMetadata) {
              let topVal = topicTitleHeight / 2 + 30;
              const webinarBanner = document.querySelector(".webinar-banner");
              if (webinarBanner) {
                topVal += webinarBanner.getBoundingClientRect().height + 15;
              }
              lastTopMetadataValue = topVal;
              topicMetadata.style.top = `-${topVal}px`;
            }
          }
          const tvEpisodeContainer = document.querySelector(".regular.tv-episode");
          if (tvEpisodeContainer.classList.contains("tv-series")) {
            document.body.classList.add("with-tv-series-background");
          } else {
            createEpisodeBackgroundEl();
          }
        };
        const createEpisodeBackgroundEl = function () {
          const mainOutlet = document.getElementById("main-outlet");
          const selector = "tv-episode-background";
          let backgroundEl = mainOutlet.querySelector(`#${selector}`);
          if (!backgroundEl) {
            backgroundEl = document.createElement("div");
            backgroundEl.id = selector;
            mainOutlet.prepend(backgroundEl);
            document.body.classList.add("tv-episode");
          }
          resizeEpisodeBackgroundEl(backgroundEl);
          window.addEventListener("resize", () => {
            resizeEpisodeBackgroundEl(backgroundEl);
          });
        };
        const resizeEpisodeBackgroundEl = function (element) {
          const video = document.querySelector(".tv-video.video-js");
          if (!video) {
            return;
          }
          element.style.height = `${window.pageYOffset + video.getBoundingClientRect().top + video.getBoundingClientRect().height + 1}px`;
        };
        const appEvents = container.lookup("service:app-events");
        appEvents.on("discourse-zoom:webinar-loaded", this, function () {
          (0, _runloop.later)(() => {
            const topicOwnerDiv = document.querySelector(".topic-owner-tv-episode");
            if (!topicOwnerDiv) {
              return;
            }
            const topicMetadata = document.querySelector(".topic-meta-data");
            if (!topicMetadata) {
              return;
            }
            const webinarBanner = document.querySelector(".webinar-banner");
            if (webinarBanner) {
              lastTopMetadataValue += webinarBanner.getBoundingClientRect().height - 120;
              topicMetadata.style.top = `-${lastTopMetadataValue}px`;
            }
          });
        });
        window.addEventListener("resize", resizeDsptvContainer);
        const removeAllChildNodes = function (parent) {
          while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
          }
        };

        // post menu
        const trackedProperties = ["episodes", "is_tv", "is_tv_episode"];
        api.addTrackedPostProperties(...trackedProperties);
        api.renderBeforeWrapperOutlet("post-menu", _tvEpisodes.default);
        api.includePostAttributes(...trackedProperties);
        api.decorateWidget("post-stream:before", () => {
          return (0, _virtualDom.h)("div.tv-episode-container");
        });
        const modifyLayoutForDsptv = function (post, videoTag, topicContainerClass) {
          const topicContainer = document.querySelector(`body.${topicContainerClass} .wrap .ember-view.regular`);
          if (!videoTag) {
            if (post.episodes) {
              // Series page without a video. Dark without layout change
              topicContainer.classList.add("tv-series-without-video");
              document.body.classList.add("with-tv-series-background");
            }
            return;
          }
          topicContainer.classList.add("tv-episode");
          if (post.episodes) {
            topicContainer.classList.add("tv-series");
          }
          const topicOwner = document.querySelector(".topic-owner");
          if (topicOwner) {
            topicOwner.classList.add("topic-owner-tv-episode");
          }
          const videoContainer = document.createElement("article");
          videoContainer.classList.add("tv-video-container");
          const tvContainer = document.querySelector(".tv-connector");
          tvContainer.appendChild(videoContainer);
          videoTag.classList.add("tv-video", "video-js", "vjs-default-skin", "vjs-big-play-centered");
          const articleDataset = videoTag.closest("article").dataset;
          for (const key in articleDataset) {
            if (Object.prototype.hasOwnProperty.call(articleDataset, key)) {
              videoContainer.dataset[key] = articleDataset[key];
            }
          }
          videoContainer.appendChild(videoTag);
          resizeDsptvContainer();
          const video = (0, _videojsInitializer.default)(videoTag, appEvents, {
            noClasses: true
          });

          // Only attach handlers on episode pages
          const shouldUpdateWatchedTimestamp = siteSettings.enable_update_episode_watched_timestamp && !post.episodes;
          if (shouldUpdateWatchedTimestamp) {
            const topicId = post.topic_id.toString();
            video.on("firstplay", () => {
              (0, _updateEpisodeWatchedTimestamp.updateEpisodePartiallyWatchedTimestamp)(topicId);
            });
            video.on("ended", () => {
              (0, _updateEpisodeWatchedTimestamp.updateEpisodeFullyWatchedTimestamp)(topicId);
            });
          }
          document.body.dataset.tv_episode_decorated = window.location.pathname;
        };
        api.decorateCookedElement((element, decoratorHelper) => {
          const model = decoratorHelper ? decoratorHelper.getModel() : null;
          if (model && model.is_tv_episode) {
            emptyDsptvContainer();
            const suggestedTopicsWrapper = document.querySelector(".suggested-topics-wrapper");
            if (suggestedTopicsWrapper) {
              suggestedTopicsWrapper.style.display = "none";
            }
            const videoContainer = element.querySelector("div[data-tv-video],div[data-dsptv-video]");
            let videoEl;
            if (videoContainer) {
              videoEl = videoContainer.querySelector("video");
            }
            (0, _runloop.later)(() => {
              const categoryClass = `category-${model.topic.category.fullSlug}`;
              modifyLayoutForDsptv(model, videoEl, categoryClass);
            });
          }
        }, {
          id: "tv-content"
        });
        const emptyDsptvContainer = function () {
          // // remove the tv_episode_decorated flag in the body's data attribute
          delete document.body.dataset.tv_episode_decorated;

          // clear the tv episode container
          const tvContainer = document.querySelector(".tv-connector");
          if (tvContainer) {
            removeAllChildNodes(tvContainer);
            tvContainer.classList.remove("has-content");
          }
        };
        api.onPageChange(() => {
          // Since both appEvents.trigger("page:changed", ...) and modifyLayoutForDsptv() are
          // run later in the ember run loop, a race condition may occur in which the page changed
          // event is triggered after modifyLayoutForDsptv() is executed. As consequence the
          // decoration for the element would be removed by emptyDsptvContainer();
          //
          // This was the cause of the issue reported in:
          // https://meta.discourse.org/t/sprint-74-oct-10-oct-21/241472/16
          //
          // The if-condition below ensures that emptyDsptvContainer() is only executed in this
          // context when the current page is different from the one that was last decorated by
          // modifyLayoutForDsptv(). This is safe because modifyLayoutForDsptv() is called after
          // emptyDsptvContainer() in api.decorateCookedElement and we only need to call it here to
          // clean-up the page if the user navigates to a page that does not contain tv-episode
          // decoration
          if (document.body.dataset.tv_episode_decorated !== window.location.pathname) {
            emptyDsptvContainer();
            document.body.classList.remove("with-tv-series-background");
            document.body.classList.remove("tv-episode");
            const elementBackgroundEl = document.getElementById("tv-episode-background");
            if (elementBackgroundEl) {
              elementBackgroundEl.remove();
            }
          }
          if (window.location.pathname.startsWith("/tv")) {
            document.body.classList.add("with-tv-homepage-background");
          } else {
            document.body.classList.remove("with-tv-homepage-background");
          }
        });
      });
    }
  };
});
define("discourse/plugins/discourse-global-communities/discourse/templates/user-amazon-contacts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-contacts__container">
    {{#if this.availableGroups}}
      {{#each this.availableGroups as |group|}}
        <Agc::Contacts::Section
          @id={{group.id}}
          @items={{group.items}}
          @primaryGroup={{this.primaryGroup}}
        />
      {{/each}}
    {{else}}
      <p class="agc-contacts__none-found">
        {{i18n "user.no_contacts"}}
      </p>
    {{/if}}
  </div>
  */
  {
    "id": "Eh9dbzFd",
    "block": "[[[10,0],[14,0,\"agc-contacts__container\"],[12],[1,\"\\n\"],[41,[30,0,[\"availableGroups\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"availableGroups\"]]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@id\",\"@items\",\"@primaryGroup\"],[[30,1,[\"id\"]],[30,1,[\"items\"]],[30,0,[\"primaryGroup\"]]]],null],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"    \"],[10,2],[14,0,\"agc-contacts__none-found\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"user.no_contacts\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"group\"],false,[\"div\",\"if\",\"each\",\"-track-array\",\"agc/contacts/section\",\"p\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/user-amazon-contacts.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/components/topic-category-expert-question-banner", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    <div class="alert topic-category-expert-question-banner">
      {{i18n "agc.category_experts_question_banner.new"}}
      <a class="composer-link" href {{action "openComposer"}}>
        {{i18n
          "agc.category_experts_question_banner.ask_dsp_guide"
          categoryName=this.topic.category.name
        }}
      </a>
      {{i18n "agc.category_experts_question_banner.to_respond"}}
    </div>
  {{/if}}
  */
  {
    "id": "qubCNUgz",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[10,0],[14,0,\"alert topic-category-expert-question-banner\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"agc.category_experts_question_banner.new\"],null]],[1,\"\\n    \"],[11,3],[24,0,\"composer-link\"],[24,6,\"\"],[4,[38,4],[[30,0],\"openComposer\"],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"agc.category_experts_question_banner.ask_dsp_guide\"],[[\"categoryName\"],[[30,0,[\"topic\",\"category\",\"name\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,2],[\"agc.category_experts_question_banner.to_respond\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"i18n\",\"a\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/topic-category-expert-question-banner.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    show(topic, isQuestion, categoryAllowingQuestions) {
      return categoryAllowingQuestions && this.currentUser && this.currentUser.id === topic.user_id && !isQuestion && moment().diff(topic.created_at, "minutes") < 4;
    },
    openComposer() {
      this.editFirstPost();
    }
  }, [["method", "show", [(0, _decorators.default)("topic", "topic.is_category_expert_question", "topic.category.allowingCategoryExpertQuestions")]], ["method", "openComposer", [_object.action]]])));
});
define("discourse/plugins/discourse-global-communities/discourse/templates/connect", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="connect-wrapper directory-wrapper">
    <Circles::SimplifiedNavigationHeader @controller={{this}} />
  
    {{#if this.showCreateCallToAction}}
      <div class="circles-create-call-to-action">
        {{html-safe (i18n "agc.circles.create_call_to_action")}}
      </div>
    {{/if}}
    <ConditionalLoadingSpinner @condition={{this.isLoading}}>
      {{#each this.views as |section|}}
        <GcCirclesSection
          @model={{section}}
          @title={{section.title}}
          @onViewAll={{action "viewAll" section.name}}
        />
      {{/each}}
      {{#if this.notFound}}
        {{i18n "agc.connect.circles_not_found"}}
      {{/if}}
    </ConditionalLoadingSpinner>
  </div>
  */
  {
    "id": "fX9+XBDs",
    "block": "[[[10,0],[14,0,\"connect-wrapper directory-wrapper\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@controller\"],[[30,0]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showCreateCallToAction\"]],[[[1,\"    \"],[10,0],[14,0,\"circles-create-call-to-action\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[[28,[37,4],[\"agc.circles.create_call_to_action\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,5],null,[[\"@condition\"],[[30,0,[\"isLoading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"views\"]]],null]],null],null,[[[1,\"      \"],[8,[39,8],null,[[\"@model\",\"@title\",\"@onViewAll\"],[[30,1],[30,1,[\"title\"]],[28,[37,9],[[30,0],\"viewAll\",[30,1,[\"name\"]]],null]]],null],[1,\"\\n\"]],[1]],null],[41,[30,0,[\"notFound\"]],[[[1,\"      \"],[1,[28,[35,4],[\"agc.connect.circles_not_found\"],null]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[\"section\"],false,[\"div\",\"circles/simplified-navigation-header\",\"if\",\"html-safe\",\"i18n\",\"conditional-loading-spinner\",\"each\",\"-track-array\",\"gc-circles-section\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connect.hbs",
    "isStrictMode": false
  });
});
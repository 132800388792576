define("discourse/plugins/discourse-global-communities/discourse/components/agc-radio-buttons", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse/plugins/discourse-global-communities/discourse/helpers/agc-cw-events-helper", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajaxError, _agcCwEventsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @label}}
    <h4 class="radio-label">{{i18n @label}}</h4>
  {{/if}}
  {{#each @options as |option|}}
    <div class={{concat "agc-radio-button-group " @class}}>
      <input
        type="radio"
        value={{option.label}}
        checked={{eq this.selection option.id}}
        {{on "click" (fn this.updateSelection option.id)}}
      />
      <div
        class="agc-radio-button"
        id={{option.id}}
        value={{option.label}}
        {{on "click" (fn this.updateSelection option.id)}}
        selected={{eq this.selection option.id}}
        role="button"
      ></div>
      <h4 for={{option.id}} class="agc-radio-button-label">{{option.label}}</h4>
    </div>
  {{/each}}
  */
  {
    "id": "87IzMr7a",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"h4\"],[14,0,\"radio-label\"],[12],[1,[28,[35,2],[[30,1]],null]],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,4],[[28,[37,4],[[30,2]],null]],null],null,[[[1,\"  \"],[10,0],[15,0,[28,[37,6],[\"agc-radio-button-group \",[30,4]],null]],[12],[1,\"\\n    \"],[11,\"input\"],[16,2,[30,3,[\"label\"]]],[16,\"checked\",[28,[37,8],[[30,0,[\"selection\"]],[30,3,[\"id\"]]],null]],[24,4,\"radio\"],[4,[38,9],[\"click\",[28,[37,10],[[30,0,[\"updateSelection\"]],[30,3,[\"id\"]]],null]],null],[12],[13],[1,\"\\n    \"],[11,0],[24,0,\"agc-radio-button\"],[16,1,[30,3,[\"id\"]]],[16,2,[30,3,[\"label\"]]],[16,\"selected\",[28,[37,8],[[30,0,[\"selection\"]],[30,3,[\"id\"]]],null]],[24,\"role\",\"button\"],[4,[38,9],[\"click\",[28,[37,10],[[30,0,[\"updateSelection\"]],[30,3,[\"id\"]]],null]],null],[12],[13],[1,\"\\n    \"],[10,\"h4\"],[15,\"for\",[30,3,[\"id\"]]],[14,0,\"agc-radio-button-label\"],[12],[1,[30,3,[\"label\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[3]],null]],[\"@label\",\"@options\",\"option\",\"@class\"],false,[\"if\",\"h4\",\"i18n\",\"each\",\"-track-array\",\"div\",\"concat\",\"input\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc-radio-buttons.hbs",
    "isStrictMode": false
  });
  const COMPONENT_NAME = "radio_button";
  class AgcRadioButtons extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "chatAudioManager", [_service.service]))();
    #chatAudioManager = (() => (dt7948.i(this, "chatAudioManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "selection", [_tracking.tracked]))();
    #selection = (() => (dt7948.i(this, "selection"), void 0))();
    model;
    type;
    userOptionField;
    eventType;
    constructor(owner, args) {
      super(owner, args);
      this.model = args.outletArgs.model;
      this.userOptionField = args.userOptionField;
      this.type = args.type;
      this.selection = this.model.get(`user_option.${this.userOptionField}`);
      this.eventType = args.eventType;
    }
    updateSelection(id) {
      const previousSelection = this.selection;
      this.selection = id;
      if (this.type === "sound") {
        this.chatAudioManager.playImmediately(this.selection);
      }
      this.model.set(`user_option.${this.userOptionField}`, id);
      const startTime = Date.now();
      this.model.save(this.userOptionField).then(() => {
        (0, _agcCwEventsHelper.recordEventLatency)(this.eventType, Date.now() - startTime, {
          user_option_field: this.userOptionField,
          component: COMPONENT_NAME,
          preference_selection: this.selection
        });
      }).catch(error => {
        (0, _ajaxError.popupAjaxError)(error);
        (0, _agcCwEventsHelper.recordEventError)(`${this.eventType}_error`, error, {
          user_option_field: this.userOptionField,
          component: COMPONENT_NAME,
          preference_selection: this.selection
        });
        this.selection = previousSelection;
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateSelection", [_object.action]))();
  }
  _exports.default = AgcRadioButtons;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcRadioButtons);
});
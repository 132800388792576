define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-user-bookmarks/bookmarks-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1 class="saved-items-header">{{i18n "agc.navs.saved_items"}}</h1>
  */
  {
    "id": "Qbc7D/Ku",
    "block": "[[[10,\"h1\"],[14,0,\"saved-items-header\"],[12],[1,[28,[35,1],[\"agc.navs.saved_items\"],null]],[13]],[],false,[\"h1\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/above-user-bookmarks/bookmarks-header.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/helpers/improved-search-helpers", ["exports", "discourse-i18n"], function (_exports, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tabHasFilters = _exports.sendRequestLatencyToRum = _exports.getErrorMessage = void 0;
  /* global cwr */

  /**
   * Generate an Improved Search error message coming from Discourse's API calls.
   *
   * error.jqXHR is the path for Discourse errors
   *
   * No documentation of any other so we default to 'An unknown error has occurred'
   *
   * See below links on how Discourse JSON response errors are utilized.
   * https://github.com/discourse/discourse/blob/64171730827c58df26a7ad75f0e58f17c2add118/lib/json_error.rb
   * https://github.com/search?q=org%3Adiscourse+%22failed_json%22&type=code
   *
   * @param {*} error
   * @returns String
   */
  const getErrorMessage = error => {
    const errorCode = error?.jqXHR?.status;
    let errorMessage = "";
    switch (errorCode) {
      case 400:
        errorMessage = _discourseI18n.default.t("agc.search-tabs.error-modal.invalid-parameters");
        break;
      case 429:
        errorMessage = _discourseI18n.default.t("agc.search-tabs.error-modal.throttling-error");
        break;
      case 500:
        errorMessage = _discourseI18n.default.t("agc.search-tabs.error-modal.internal-error");
        break;
      default:
        return {
          errorCode: null,
          errorMessage: error.message ?? _discourseI18n.default.t("agc.search-tabs.error-modal.unknown-error")
        };
    }
    return {
      errorCode,
      errorMessage
    };
  };
  _exports.getErrorMessage = getErrorMessage;
  const tabHasFilters = filterCache => {
    if (filterCache) {
      const filterCount = Object.keys(filterCache).filter(x => filterCache[x].id != null).length;
      return filterCount > 0;
    }
    return false;
  };
  _exports.tabHasFilters = tabHasFilters;
  const sendRequestLatencyToRum = (requestLatency, tabName) => {
    cwr("recordEvent", {
      type: "search_api_latency",
      data: {
        tab: tabName,
        latency: requestLatency
      }
    });
  };
  _exports.sendRequestLatencyToRum = sendRequestLatencyToRum;
});
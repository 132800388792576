define("discourse/plugins/discourse-global-communities/discourse/routes/admin-plugins-agc-contacts", ["exports", "discourse/lib/ajax", "discourse/routes/discourse", "discourse-common/utils/decorators"], function (_exports, _ajax, _discourse, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const csvProcessedMessageBusEndpoint = "/agc-contacts-csv-processed";
  class AdminPluginsAgcContactsRoute extends _discourse.default {
    queryParams = {
      groupName: {
        refreshModel: true
      }
    };
    model(params) {
      if (params.groupName) {
        return (0, _ajax.ajax)(`/admin/plugins/agc/contacts.json`, {
          data: {
            group_name: params.groupName
          }
        });
      }
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties(model);
      controller.loading = false;
    }
    activate() {
      super.activate(...arguments);
      this.messageBus.subscribe(csvProcessedMessageBusEndpoint, this.displayCsvProcessedMessage);
    }
    deactivate() {
      super.deactivate(...arguments);
      this.messageBus.unsubscribe(csvProcessedMessageBusEndpoint, this.displayCsvProcessedMessage);
    }
    displayCsvProcessedMessage(msg) {
      this.contactsController.displayCsvProcessedMessage(msg);
    }
    static #_ = (() => dt7948.n(this.prototype, "displayCsvProcessedMessage", [_decorators.bind]))();
    get contactsController() {
      return this.controllerFor("admin-plugins-agc-contacts");
    }
  }
  _exports.default = AdminPluginsAgcContactsRoute;
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc-toasts/contacts-csv-processed-toast", ["exports", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc-toast"], function (_exports, _discourseI18n, _agcToast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class contactsCsvProcessedToast extends _agcToast.default {
    get icon() {
      return "info";
    }
    get message() {
      if (this.args.data.success) {
        return _discourseI18n.default.t("amazon_contacts.csv_processed_toast.success", {
          group_name: this.args.data.group_name
        });
      }
      return _discourseI18n.default.t("amazon_contacts.csv_processed_toast.error", {
        group_name: this.args.data.group_name,
        error: this.args.data.errors.join(", ")
      });
    }
  }
  _exports.default = contactsCsvProcessedToast;
});
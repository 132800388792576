define("discourse/plugins/discourse-global-communities/discourse/components/no-search-results", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/helpers/config/communityStage", "@ember/template-factory"], function (_exports, _component, _object, _service, _communityStage, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-improved-search-no-results">
    <div class="agc-improved-search-no-results-icon">
      <div class="no-results-icon"></div>
      <h1>{{i18n "agc.search-tabs.no-results-page.no-results"}}</h1>
    </div>
  
    <h3><b>{{i18n "agc.search-tabs.no-results-page.search-tips"}}</b></h3>
    <ul>
      <h3>
        <li>{{i18n "agc.search-tabs.no-results-page.check-spelling"}}</li>
        <li>{{i18n "agc.search-tabs.no-results-page.search-shorter-terms"}}</li>
        <li>{{i18n "agc.search-tabs.no-results-page.alternate-terms"}}</li>
      </h3>
    </ul>
    <h3><b>{{i18n "agc.search-tabs.no-results-page.ask-community"}}</b></h3>
    <h3>{{i18n "agc.search-tabs.no-results-page.ask-members.cannot-find"}}
      <a class="open-editor" role="button" {{action "writePost"}}>
        {{i18n "agc.search-tabs.no-results-page.ask-members.asking-community"}}
      </a>
      {{i18n
        "agc.search-tabs.no-results-page.ask-members.partner-name"
        partnerName=this.partnerName
      }}
    </h3>
  </div>
  */
  {
    "id": "40nfNyzO",
    "block": "[[[10,0],[14,0,\"agc-improved-search-no-results\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"agc-improved-search-no-results-icon\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"no-results-icon\"],[12],[13],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.no-results\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"h3\"],[12],[10,\"b\"],[12],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.search-tips\"],null]],[13],[13],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.check-spelling\"],null]],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.search-shorter-terms\"],null]],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.alternate-terms\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"h3\"],[12],[10,\"b\"],[12],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.ask-community\"],null]],[13],[13],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.ask-members.cannot-find\"],null]],[1,\"\\n    \"],[11,3],[24,0,\"open-editor\"],[24,\"role\",\"button\"],[4,[38,8],[[30,0],\"writePost\"],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.ask-members.asking-community\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,2],[\"agc.search-tabs.no-results-page.ask-members.partner-name\"],[[\"partnerName\"],[[30,0,[\"partnerName\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"h1\",\"i18n\",\"h3\",\"b\",\"ul\",\"li\",\"a\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/no-search-results.hbs",
    "isStrictMode": false
  });
  class NoSearchResults extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    init() {
      super.init(...arguments);
      this.set("partnerName", (0, _communityStage.getPartnerName)());
    }

    // opens topic composer
    writePost() {
      this.composer.openNewTopic();
    }
    static #_3 = (() => dt7948.n(this.prototype, "writePost", [_object.action]))();
  }
  _exports.default = NoSearchResults;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NoSearchResults);
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc-improved-search", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember-compat/tracked-built-ins", "rsvp", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/helpers/analytics/improvedSearchAnalyticsAnnotator", "discourse/plugins/discourse-global-communities/discourse/helpers/improved-search-helpers", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _trackedBuiltIns, _rsvp, _discourseI18n, _appEventsConfig, improvedSearchAnalytics, _improvedSearchHelpers, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="agc-search-container"
    {{did-update this.scrollToCachedPosition this.selectedTab}}
  >
    <TabHeader
      @selectedTab={{this.selectedTab}}
      @tabChangeHandler={{this.tabChangeHandler}}
      @tabs={{this.tabs}}
    />
  
    {{#if this.isNotPostsTab}}
      <div
        class="agc-search-results
          {{unless (eq this.getSearchResultsState 'results') 'hidden'}}"
        id="agc-search-results"
        {{did-update this.initSearchResultsCache @outletArgs.searchTerm}}
      >
        <div class="agc-result-count {{if this.isLoading 'hidden'}}">
          {{if
            (eq this.getSearchResultsCount 1)
            (i18n "agc.search-tabs.show-result" count=this.getSearchResultsCount)
            (i18n "agc.search-tabs.show-results" count=this.getSearchResultsCount)
          }}
        </div>
        <TabContent
          @componentName={{this.selectedTab}}
          @searchTerm={{@outletArgs.searchTerm}}
          @resultsCache={{this.getSelectedTabCache}}
        />
      </div>
  
      <div
        class="agc-search-results
          {{unless (eq this.getSearchResultsState 'no-results') 'hidden'}}"
      >
        <NoSearchResults />
      </div>
  
      <div
        class="agc-search-error
          {{unless (eq this.getSearchResultsState 'error') 'hidden'}}"
      >
        <TabError @errorMessage={{this.getSelectedTabError}} />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "29J4ZVb1",
    "block": "[[[11,0],[24,0,\"agc-search-container\"],[4,[38,1],[[30,0,[\"scrollToCachedPosition\"]],[30,0,[\"selectedTab\"]]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@selectedTab\",\"@tabChangeHandler\",\"@tabs\"],[[30,0,[\"selectedTab\"]],[30,0,[\"tabChangeHandler\"]],[30,0,[\"tabs\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isNotPostsTab\"]],[[[1,\"    \"],[11,0],[16,0,[29,[\"agc-search-results\\n        \",[52,[51,[28,[37,5],[[30,0,[\"getSearchResultsState\"]],\"results\"],null]],\"hidden\"]]]],[24,1,\"agc-search-results\"],[4,[38,1],[[30,0,[\"initSearchResultsCache\"]],[30,1,[\"searchTerm\"]]],null],[12],[1,\"\\n      \"],[10,0],[15,0,[29,[\"agc-result-count \",[52,[30,0,[\"isLoading\"]],\"hidden\"]]]],[12],[1,\"\\n        \"],[1,[52,[28,[37,5],[[30,0,[\"getSearchResultsCount\"]],1],null],[28,[37,6],[\"agc.search-tabs.show-result\"],[[\"count\"],[[30,0,[\"getSearchResultsCount\"]]]]],[28,[37,6],[\"agc.search-tabs.show-results\"],[[\"count\"],[[30,0,[\"getSearchResultsCount\"]]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,7],null,[[\"@componentName\",\"@searchTerm\",\"@resultsCache\"],[[30,0,[\"selectedTab\"]],[30,1,[\"searchTerm\"]],[30,0,[\"getSelectedTabCache\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"agc-search-results\\n        \",[52,[51,[28,[37,5],[[30,0,[\"getSearchResultsState\"]],\"no-results\"],null]],\"hidden\"]]]],[12],[1,\"\\n      \"],[8,[39,8],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"agc-search-error\\n        \",[52,[51,[28,[37,5],[[30,0,[\"getSearchResultsState\"]],\"error\"],null]],\"hidden\"]]]],[12],[1,\"\\n      \"],[8,[39,9],null,[[\"@errorMessage\"],[[30,0,[\"getSelectedTabError\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@outletArgs\"],false,[\"div\",\"did-update\",\"tab-header\",\"if\",\"unless\",\"eq\",\"i18n\",\"tab-content\",\"no-search-results\",\"tab-error\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc-improved-search.hbs",
    "isStrictMode": false
  });
  class AgcImprovedSearch extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "selectedTab", [_tracking.tracked]))();
    #selectedTab = (() => (dt7948.i(this, "selectedTab"), void 0))();
    searchResultsCache = (() => new _trackedBuiltIns.TrackedObject())();
    tabs;
    constructor(owner, args) {
      super(owner, args);
      this.appEvents.on(_appEventsConfig.TAB_CONTENTS, this, this.handleTabContentActions);
      this.appEvents.on(_appEventsConfig.TAB_CONTENTS_ERROR, this, this.handleTabContentErrors);
      this.tabs = this.getCommunityTabs();
      this.selectedTab = this.selectFirstTabAsDefault();
      this.initSearchResultsCache();
      document.body.classList.remove("agc-hide-core-search-results");
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.TAB_CONTENTS, this, this.handleTabContentActions);
      this.appEvents.off(_appEventsConfig.TAB_CONTENTS_ERROR, this, this.handleTabContentErrors);
    }
    initSearchResultsCache() {
      this.tabs.forEach(tab => {
        this.searchResultsCache[tab.componentName] = new _trackedBuiltIns.TrackedObject({
          results: null,
          // a falsy value is needed to tell the tabs that we have not gotten any results yet
          errorMessage: null,
          filterCache: {},
          pageNumber: 0,
          hasReachedLastPage: false,
          scrollXPosition: 0,
          scrollYPosition: 0
        });
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "initSearchResultsCache", [_object.action]))();
    tabChangeHandler(event) {
      this.getSelectedTabCache.scrollXPosition = window.scrollX;
      this.getSelectedTabCache.scrollYPosition = window.scrollY;
      this.selectedTab = event.target.id;
      improvedSearchAnalytics.triggerTabToggleAnalytics(this.appEvents, {
        selectedTab: this.selectedTab
      });
      document.getElementById(this.selectedTab).scrollIntoView({
        behavior: "smooth",
        inline: "center"
      });
      if (this.isNotPostsTab) {
        document.body.classList.add("agc-hide-core-search-results");
      } else {
        document.body.classList.remove("agc-hide-core-search-results");
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "tabChangeHandler", [_object.action]))();
    handleTabContentActions(args) {
      switch (args?.action) {
        case "results":
          const tabCache = this.searchResultsCache[args.tab];
          tabCache.errorMessage = null;
          tabCache.results = args.value;
          tabCache.pageNumber = args.pageNumber;
          tabCache.hasReachedLastPage = args.hasReachedLastPage;
          tabCache.filterCache = args.filterCache;
          this.runTabUpdateAnalytics(args);
          break;
        default:
          throw new Error(`Action [${args?.action}] is not supported by search tabs.`);
      }
    }
    handleTabContentErrors(args) {
      const tabCache = this.searchResultsCache[args.tab];
      const formattedError = (0, _improvedSearchHelpers.getErrorMessage)(args.error);
      tabCache.errorMessage = formattedError.errorMessage;
      tabCache.results = [];
      improvedSearchAnalytics.triggerSearchErrorAnalytics(this.appEvents, {
        errorCode: formattedError.errorCode,
        errorMessage: formattedError.errorMessage
      });
      if (!window.cwr) {
        return;
      }
      window.cwr("recordEvent", {
        type: "search_errors",
        data: {
          tab: args.tab,
          errorCode: formattedError.errorCode,
          errorMessage: formattedError.errorMessage
        }
      });
    }
    async scrollToCachedPosition(element, selectedTab) {
      const selectedTabCache = this.searchResultsCache[selectedTab];
      await new _rsvp.Promise(r => setTimeout(r, 0));
      window.scrollTo(selectedTabCache.scrollXPosition, selectedTabCache.scrollYPosition);
    }
    static #_7 = (() => dt7948.n(this.prototype, "scrollToCachedPosition", [_object.action]))();
    get isNotPostsTab() {
      return this.selectedTab !== "posts-tab";
    }
    get getSelectedTabCache() {
      return this.searchResultsCache[this.selectedTab];
    }
    get isLoading() {
      return !this.getSelectedTabResults;
    }
    get getSelectedTabResults() {
      return this.getSelectedTabCache.results;
    }
    get getSelectedTabError() {
      return this.getSelectedTabCache.errorMessage;
    }
    get getSearchResultsCount() {
      return this.getSelectedTabCache.results?.length ?? 0;
    }
    get getSearchResultsState() {
      if (this.searchResultsCache[this.selectedTab].errorMessage) {
        return "error";
      }
      return !this.isLoading && this.getSearchResultsCount <= 0 ? "no-results" : "results";
    }
    getCommunityTabs() {
      const tabsForCommunity = this.siteSettings.improved_search_tabs.toString().split("|");
      if (!this.currentUser?.can_use_circles && tabsForCommunity.includes("groups")) {
        tabsForCommunity.splice(tabsForCommunity.indexOf("groups"), 1);
      }
      return tabsForCommunity.map(tab => ({
        componentName: `${tab}-tab`,
        label: (0, _discourseI18n.i18n)(`agc.search-tabs.${tab}`)
      }));
    }
    selectFirstTabAsDefault() {
      if (this.tabs) {
        return this.selectedTab = this.tabs[0].componentName;
      }
    }
    runTabUpdateAnalytics(args) {
      if (args.value.length === 0) {
        improvedSearchAnalytics.triggerNoSearchResultsAnalytics(this.appEvents, {
          tab: args.tab,
          searchTerm: this.args.outletArgs.searchTerm
        });
      } else {
        improvedSearchAnalytics.triggerPaginationAnalytics(this.appEvents, {
          tab: args.tab,
          searchTerm: this.args.outletArgs.searchTerm,
          pageNumber: args.pageNumber,
          resultKeys: args.value.map(result => args.extractAnalyticsKeys(result))
        });
      }
      if (!window.cwr) {
        return;
      }
      window.cwr("recordEvent", {
        type: "search_results_count",
        data: {
          tab: args.tab,
          searchTerm: this.args.outletArgs.searchTerm,
          count: args.value.length
        }
      });
    }
  }
  _exports.default = AgcImprovedSearch;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcImprovedSearch);
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc-infinite-scroll", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _appEventsConfig, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="agc-search-results-infinite-scroll"
    {{did-update this.tabUpdated @tabName @resultsCache.results}}
    {{did-update this.searchTermUpdated @searchTerm}}
  >
  
    {{yield}}
  
    {{#if this.shouldShowLoadMore}}
      {{agc-custom-button
        type="primary"
        label=(i18n "agc.search-tabs.load-more")
        onClick=this.loadMore
        class="agc-search-load-more-button"
      }}
    {{/if}}
  
    <div class="spinner {{unless this.isLoading 'hidden'}}"></div>
  
    {{#if this.hasReachedLastPage}}
      <h3 class="agc-search-no-more-results">
        {{i18n "search.no_more_results"}}
      </h3>
    {{else if this.hasReachedMaxPages}}
      <h3 class="agc-search-more-results">
        {{i18n "search.more_results"}}
      </h3>
    {{/if}}
  
  </div>
  */
  {
    "id": "fvRy2kRL",
    "block": "[[[11,0],[24,0,\"agc-search-results-infinite-scroll\"],[4,[38,1],[[30,0,[\"tabUpdated\"]],[30,1],[30,2,[\"results\"]]],null],[4,[38,1],[[30,0,[\"searchTermUpdated\"]],[30,3]],null],[12],[1,\"\\n\\n  \"],[18,4,null],[1,\"\\n\\n\"],[41,[30,0,[\"shouldShowLoadMore\"]],[[[1,\"    \"],[1,[28,[35,4],null,[[\"type\",\"label\",\"onClick\",\"class\"],[\"primary\",[28,[37,5],[\"agc.search-tabs.load-more\"],null],[30,0,[\"loadMore\"]],\"agc-search-load-more-button\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[15,0,[29,[\"spinner \",[52,[51,[30,0,[\"isLoading\"]]],\"hidden\"]]]],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasReachedLastPage\"]],[[[1,\"    \"],[10,\"h3\"],[14,0,\"agc-search-no-more-results\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"search.no_more_results\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasReachedMaxPages\"]],[[[1,\"    \"],[10,\"h3\"],[14,0,\"agc-search-more-results\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"search.more_results\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[1,\"\\n\"],[13]],[\"@tabName\",\"@resultsCache\",\"@searchTerm\",\"&default\"],false,[\"div\",\"did-update\",\"yield\",\"if\",\"agc-custom-button\",\"i18n\",\"unless\",\"h3\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc-infinite-scroll.hbs",
    "isStrictMode": false
  });
  class AgcInfiniteScroll extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    /**
     * Method reference that binds 'this' context to scroll handler.
     */
    boundScrollHandler;
    constructor(owner, args) {
      super(owner, args);
      this.boundScrollHandler = this.scrollHandler.bind(this);
      document.addEventListener("scroll", this.boundScrollHandler, {
        passive: true
      });
      this.appEvents.on(_appEventsConfig.FILTERS_POST_TAB, this.searchTermUpdated);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      document.removeEventListener("scroll", this.boundScrollHandler);
      this.appEvents.off(_appEventsConfig.FILTERS_POST_TAB, this.searchTermUpdated);
    }
    get tabName() {
      return this.args.tabName;
    }
    get resultsCount() {
      return this.args.resultsCache.results?.length;
    }
    get pageNumber() {
      return this.args.resultsCache.pageNumber;
    }
    get hasReachedLastPage() {
      return this.args.resultsCache.hasReachedLastPage && this.resultsCount > 0;
    }
    get hasReachedMaxPages() {
      return this.pageNumber >= this.siteSettings.improved_search_max_pages - 1; // -1 because page numbers start at 0
    }

    /**
     * Show Load more button if we are on the first page, but not if the first page of results is not a full page.
     */
    get shouldShowLoadMore() {
      return this.pageNumber === 0 && this.resultsCount >= this.siteSettings.improved_search_page_size && !this.isLoading;
    }
    tabUpdated() {
      this.isLoading = !this.args.resultsCache.results;
    }
    static #_4 = (() => dt7948.n(this.prototype, "tabUpdated", [_object.action]))();
    searchTermUpdated() {
      this.isLoading = true;
    }
    static #_5 = (() => dt7948.n(this.prototype, "searchTermUpdated", [_object.action]))();
    loadMore() {
      if (!this.hasReachedMaxPages) {
        this.isLoading = true;
        this.appEvents.trigger(_appEventsConfig.SEARCH_LOAD_MORE, {
          tab: this.tabName
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    scrollHandler() {
      const element = document.getElementById("agc-search-results");
      if (element && !element.className.includes("hidden")) {
        const elementBottom = element.offsetTop + element.offsetHeight;
        const bottomReached = window.scrollY + window.innerHeight > elementBottom;
        if (bottomReached && !this.isLoading && !this.hasReachedLastPage && this.pageNumber !== 0) {
          this.loadMore();
        }
      }
    }
  }
  _exports.default = AgcInfiniteScroll;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcInfiniteScroll);
});
define("discourse/plugins/discourse-global-communities/discourse/templates/explore", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="explore-wrapper">
    {{#if this.model.categories.length}}
      <div class="directory-body">
        {{#each this.model.categories as |category|}}
          {{category-title-link category=category}}
        {{/each}}
      </div>
    {{else}}
      <div class="no-categories">
        {{i18n "agc.explore.no_categories"}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "p/LaeetT",
    "block": "[[[10,0],[14,0,\"explore-wrapper\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"categories\",\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"directory-body\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\",\"categories\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,4],null,[[\"category\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"no-categories\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"agc.explore.no_categories\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"category\"],false,[\"div\",\"if\",\"each\",\"-track-array\",\"category-title-link\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/explore.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/helpers/config/components/no-notifications-empty-state", ["exports", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NoNotificationsEmptyState extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    onButtonClicked() {
      this.router.transitionTo("/");
    }
    static #_2 = (() => dt7948.n(this.prototype, "onButtonClicked", [_object.action]))();
  }
  _exports.default = NoNotificationsEmptyState;
});
define("discourse/plugins/discourse-global-communities/discourse/services/getTopics", ["exports", "discourse/lib/ajax", "discourse/lib/search"], function (_exports, _ajax, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchTopics = void 0;
  /**
   *
   * @param {String} searchTerm
   * @param {Int} pageNumber
   * @returns Promise with Topic and post data
   */

  const fetchTopics = async fetchParams => {
    const args = {
      q: fetchParams.searchTerm,
      page: fetchParams.pageNumber
    };
    return (0, _ajax.ajax)("/search", {
      data: args
    }).then(async results => {
      const model = (await (0, _search.translateResults)(results)) || {};
      model.grouped_search_result = results.grouped_search_result;
      return model;
    });
  };
  _exports.fetchTopics = fetchTopics;
});
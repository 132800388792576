define("discourse/plugins/discourse-global-communities/discourse/components/categories-tab", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/helpers/improved-search-helpers", "discourse/plugins/discourse-global-communities/discourse/services/getCategories", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _appEventsConfig, _improvedSearchHelpers, _getCategories, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="categories-results"
    {{did-update this.getNewCategories @searchTerm}}
  >
    {{#each this.categories as |category|}}
      <div id="category-result-{{category.slug}}">
        {{category-link category}}
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "3e0yGSbH",
    "block": "[[[11,0],[24,0,\"categories-results\"],[4,[38,1],[[30,0,[\"getNewCategories\"]],[30,1]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"    \"],[10,0],[15,1,[29,[\"category-result-\",[30,2,[\"slug\"]]]]],[12],[1,\"\\n      \"],[1,[28,[35,4],[[30,2]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"@searchTerm\",\"category\"],false,[\"div\",\"did-update\",\"each\",\"-track-array\",\"category-link\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/categories-tab.hbs",
    "isStrictMode": false
  });
  class CategoriesTab extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "categories", [_tracking.tracked], function () {
      return [];
    }))();
    #categories = (() => (dt7948.i(this, "categories"), void 0))();
    tabName;
    pageNumber;
    hasReachedLastPage;
    constructor(owner, args) {
      super(owner, args);
      this.tabName = args.tabName;
      const resultsCache = args.resultsCache;
      this.pageNumber = resultsCache.pageNumber;
      this.hasReachedLastPage = resultsCache.hasReachedLastPage;
      if (resultsCache.results) {
        this.categories = resultsCache.results;
      } else {
        this.getFilteredCategories();
      }
      this.appEvents.on(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    getNewCategories() {
      this.categories = [];
      this.pageNumber = 0;
      this.hasReachedLastPage = false;
      this.getFilteredCategories();
    }
    static #_5 = (() => dt7948.n(this.prototype, "getNewCategories", [_object.action]))();
    getFilteredCategories() {
      const maxNumResults = (this.pageNumber + 1) * this.siteSettings.improved_search_page_size;
      const startRequest = Date.now();
      (0, _getCategories.getCategories)().then(result => {
        const endRequest = Date.now();
        const requestLatency = endRequest - startRequest;
        (0, _improvedSearchHelpers.sendRequestLatencyToRum)(requestLatency, this.tabName);
        this.categories = result?.category_list?.categories.filter(c => this.filterCategory(c)).splice(0, maxNumResults);
        this.hasReachedLastPage = this.categories.length < maxNumResults;
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS, {
          action: "results",
          value: this.categories,
          tab: this.tabName,
          pageNumber: this.pageNumber,
          hasReachedLastPage: this.hasReachedLastPage,
          extractAnalyticsKeys: category => category.id
        });
      }).catch(e => {
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS_ERROR, {
          error: e,
          tab: this.tabName
        });
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "getFilteredCategories", [_object.action]))();
    filterCategory(category) {
      return category.name?.toLowerCase().includes(this.search.activeGlobalSearchTerm.toLowerCase()) && category.circle_id == null; // filter out groups/circles if they exist
    }
    getNextPage(args) {
      if (args.tab === this.tabName) {
        this.pageNumber++;
        this.getFilteredCategories();
      }
    }
  }
  _exports.default = CategoriesTab;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CategoriesTab);
});
define("discourse/plugins/discourse-global-communities/discourse/templates/tv", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="tv-root">
    {{outlet}}
  </div>
  
  {{custom-footer}}
  */
  {
    "id": "Xu5eqXup",
    "block": "[[[10,0],[14,0,\"tv-root\"],[12],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[28,[32,0],[\"custom-footer\"],null],[[[8,[39,4],null,null,null]],[]],[[[44,[[28,[37,6],null,[[\"custom-footer\"],[[28,[32,1],[\"custom-footer\"],null]]]]],[[[1,[52,[30,1,[\"custom-footer\"]],[28,[30,1,[\"custom-footer\"]],null,null],[28,[32,2],[[30,0],\"custom-footer\",\"[\\\"The `custom-footer` property path was used in the `discourse/plugins/discourse-global-communities/discourse/templates/tv.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.custom-footer}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]]],[\"maybeHelpers\"],false,[\"div\",\"component\",\"-outlet\",\"if\",\"custom-footer\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/tv.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});
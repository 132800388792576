define("discourse/plugins/discourse-global-communities/discourse/templates/tv/tag", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="tv-tag">
    <div class="section">
      <div class="tiles">
        <h2 class="section-title">{{this.title}}</h2>
        {{#each this.topics as |topic|}}
          <Agc::Carousel::TvTile @topic={{topic}} />
        {{/each}}
      </div>
    </div>
  </div>
  */
  {
    "id": "60aUJvY+",
    "block": "[[[10,0],[14,0,\"tv-tag\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tiles\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"section-title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@topic\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"topic\"],false,[\"div\",\"h2\",\"each\",\"-track-array\",\"agc/carousel/tv-tile\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/tv/tag.hbs",
    "isStrictMode": false
  });
});
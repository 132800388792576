define("discourse/plugins/discourse-global-communities/discourse/templates/components/user-fields/confirm", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.field.name}}
    <label class="control-label">
      {{html-safe this.translatedName}}
      {{#if this.field.required}}<span class="required">*</span>{{/if}}
    </label>
  {{/if}}
  
  <div class="controls">
    <label class="control-label checkbox-label">
      <Input
        id={{concat "user-" this.elementId}}
        @checked={{this.value}}
        @type="checkbox"
      />
      <span>
        {{html-safe this.translatedDescription}}
        {{#unless this.field.name}}{{#if this.field.required}}<span
              class="required"
            >*</span>{{/if}}{{/unless}}
      </span>
    </label>
  </div>
  */
  {
    "id": "gwRu0jor",
    "block": "[[[41,[30,0,[\"field\",\"name\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"translatedName\"]]],null]],[1,\"\\n    \"],[41,[30,0,[\"field\",\"required\"]],[[[10,1],[14,0,\"required\"],[12],[1,\"*\"],[13]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label checkbox-label\"],[12],[1,\"\\n    \"],[8,[39,5],[[16,1,[28,[37,6],[\"user-\",[30,0,[\"elementId\"]]],null]]],[[\"@checked\",\"@type\"],[[30,0,[\"value\"]],\"checkbox\"]],null],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,0,[\"translatedDescription\"]]],null]],[1,\"\\n      \"],[41,[51,[30,0,[\"field\",\"name\"]]],[[[41,[30,0,[\"field\",\"required\"]],[[[10,1],[14,0,\"required\"],[12],[1,\"*\"],[13]],[]],null]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"label\",\"html-safe\",\"span\",\"div\",\"input\",\"concat\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/user-fields/confirm.hbs",
    "isStrictMode": false
  });
});
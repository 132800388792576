define("discourse/plugins/discourse-global-communities/discourse/components/agc/chat/pages/start-chatting", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/chat/discourse/components/chat/modal/new-message", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _newMessage, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Agc::Chat::Pages::CallToAction
    @flavor="empty-channels"
    @title={{i18n "agc.chat.start_chatting.empty_channels.title"}}
    @message={{i18n "agc.chat.start_chatting.empty_channels.message"}}
  >
    <button
      type="button"
      class="chat-call-to-action-page__button btn"
      data-agc-analytics-event="chat:call-to-action:start-a-chat-btn-clicked"
      title={{i18n "chat.direct_messages.new"}}
      {{on "click" this.openNewMessageModal}}
    >
      {{d-icon "d-chat"}}
      {{i18n "agc.chat.start_chatting.empty_channels.btn_label"}}
    </button>
  </Agc::Chat::Pages::CallToAction>
  */
  {
    "id": "v5HkRfG3",
    "block": "[[[8,[39,0],null,[[\"@flavor\",\"@title\",\"@message\"],[\"empty-channels\",[28,[37,1],[\"agc.chat.start_chatting.empty_channels.title\"],null],[28,[37,1],[\"agc.chat.start_chatting.empty_channels.message\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[11,\"button\"],[24,0,\"chat-call-to-action-page__button btn\"],[24,\"data-agc-analytics-event\",\"chat:call-to-action:start-a-chat-btn-clicked\"],[16,\"title\",[28,[37,1],[\"chat.direct_messages.new\"],null]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"openNewMessageModal\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,4],[\"d-chat\"],null]],[1,\"\\n    \"],[1,[28,[35,1],[\"agc.chat.start_chatting.empty_channels.btn_label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"agc/chat/pages/call-to-action\",\"i18n\",\"button\",\"on\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/chat/pages/start-chatting.hbs",
    "isStrictMode": false
  });
  class AgcChatPagesStartChatting extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    openNewMessageModal() {
      this.modal.show(_newMessage.default);
    }
    static #_2 = (() => dt7948.n(this.prototype, "openNewMessageModal", [_object.action]))();
  }
  _exports.default = AgcChatPagesStartChatting;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcChatPagesStartChatting);
});
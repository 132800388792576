define("discourse/plugins/discourse-global-communities/discourse/helpers/config/components/agc-expander", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AGCExpander extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "isExpanded", [_tracking.tracked], function () {
      return false;
    }))();
    #isExpanded = (() => (dt7948.i(this, "isExpanded"), void 0))();
    toggleIsExpanded() {
      this.isExpanded = !this.isExpanded;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleIsExpanded", [_object.action]))();
  }
  _exports.default = AGCExpander;
});
define("discourse/plugins/discourse-global-communities/discourse/services/topics/fetchTopicById", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchTopicById = void 0;
  /**
   * Fetch a single topic using the topicId
   *
   * https://docs.discourse.org/#tag/Topics/operation/getTopic
   *
   * @param {string} topicId
   * @returns {Promise<*>} Topic information
   */
  const fetchTopicById = async topicId => {
    if (!topicId) {
      throw new Error("topicId is required.");
    }
    return (0, _ajax.ajax)(`/t/${topicId}.json`, {
      dataType: "json",
      type: "GET"
    });
  };
  _exports.fetchTopicById = fetchTopicById;
});
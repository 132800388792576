define("discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/excluded-from-recommendations-button", ["exports", "@glimmer/component", "@ember/object", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dButton, _concatClass, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcPostMenuExcludedFromRecommendationsButton extends _component.default {
    static shouldRender(args) {
      return args.post.can_mark_excluded_from_recommendations;
    }
    get isExcluded() {
      return this.args.post.is_excluded_from_recommendations;
    }
    async toggleExcludePostFromRecommendations() {
      const post = this.args.post;
      let previousExclusionState = post.is_excluded_from_recommendations;
      const data = {
        post_id: post.id,
        excluded: !previousExclusionState
      };
      post.set("is_excluded_from_recommendations", !previousExclusionState);
      await (0, _ajax.ajax)(`/exclude-post.json`, {
        method: "PUT",
        data
      }).catch(error => {
        post.set("is_excluded_from_recommendations", previousExclusionState);
        (0, _ajaxError.popupAjaxError)(error);
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "toggleExcludePostFromRecommendations", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class={{concatClass "exclude-button" (if this.isExcluded "is-excluded")}}
          ...attributes
          @action={{this.toggleExcludePostFromRecommendations}}
          @icon="ban"
          @title={{if
            this.isExcluded
            "discourse_gc.post_recommend_excluded_tooltip"
            "discourse_gc.post_recommend_included_tooltip"
          }}
        />
      
    */
    {
      "id": "KgzsS06P",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"exclude-button\",[52,[30,0,[\"isExcluded\"]],\"is-excluded\"]],null]],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"toggleExcludePostFromRecommendations\"]],\"ban\",[52,[30,0,[\"isExcluded\"]],\"discourse_gc.post_recommend_excluded_tooltip\",\"discourse_gc.post_recommend_included_tooltip\"]]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/excluded-from-recommendations-button.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcPostMenuExcludedFromRecommendationsButton;
});
define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/bookmark-list-table-header/agc-bookmark-list-table-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <th class="topic-list-data">{{i18n "bookmarks.topic"}}</th>
  <th class="topic-list-data author-avatar">{{i18n "bookmarks.author"}}</th>
  <th class="post-metadata topic-list-data" colspan="2">{{i18n
      "bookmarks.activity"
    }}</th>
  */
  {
    "id": "kPkYADLQ",
    "block": "[[[10,\"th\"],[14,0,\"topic-list-data\"],[12],[1,[28,[35,1],[\"bookmarks.topic\"],null]],[13],[1,\"\\n\"],[10,\"th\"],[14,0,\"topic-list-data author-avatar\"],[12],[1,[28,[35,1],[\"bookmarks.author\"],null]],[13],[1,\"\\n\"],[10,\"th\"],[14,0,\"post-metadata topic-list-data\"],[14,\"colspan\",\"2\"],[12],[1,[28,[35,1],[\"bookmarks.activity\"],null]],[13]],[],false,[\"th\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/bookmark-list-table-header/agc-bookmark-list-table-header.hbs",
    "isStrictMode": false
  });
});
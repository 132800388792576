define("discourse/plugins/discourse-global-communities/discourse/components/videos-tab", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "truth-helpers", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/helpers/improved-search-helpers", "discourse/plugins/discourse-global-communities/discourse/services/getTopics", "discourse/plugins/discourse-global-communities/discourse/components/agc/tv-episode", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didUpdate, _service, _truthHelpers, _appEventsConfig, _improvedSearchHelpers, _getTopics, _tvEpisode, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class VideosTab extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "videos", [_tracking.tracked], function () {
      return [];
    }))();
    #videos = (() => (dt7948.i(this, "videos"), void 0))();
    tabName;
    pageNumber;
    hasReachedLastPage;
    constructor(owner, args) {
      super(owner, args);
      this.tabName = args.tabName;
      const resultsCache = args.resultsCache;
      this.pageNumber = resultsCache.pageNumber;
      this.hasReachedLastPage = resultsCache.hasReachedLastPage;
      if (resultsCache.results) {
        this.videos = resultsCache.results;
      } else {
        this.getVideoPosts();
      }
      this.appEvents.on(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    getNewVideos() {
      this.videos = [];
      this.pageNumber = 0;
      this.hasReachedLastPage = false;
      this.getVideoPosts();
    }
    static #_6 = (() => dt7948.n(this.prototype, "getNewVideos", [_object.action]))();
    getVideoPosts() {
      const fetchParams = {
        searchTerm: `in:tv ${this.search.activeGlobalSearchTerm}`,
        pageNumber: this.pageNumber + 1
      };
      const startRequest = Date.now();
      (0, _getTopics.fetchTopics)(fetchParams).then(results => {
        const newVideos = results.topics;
        const endRequest = Date.now();
        const requestLatency = endRequest - startRequest;
        (0, _improvedSearchHelpers.sendRequestLatencyToRum)(requestLatency, this.tabName);
        this.videos = [...this.videos, ...newVideos];
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS, {
          action: "results",
          tab: this.tabName,
          value: this.videos,
          pageNumber: this.pageNumber,
          hasReachedLastPage: newVideos.length < this.siteSettings.improved_search_page_size,
          extractAnalyticsKeys: video => video.url
        });
      }).catch(e => {
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS_ERROR, {
          error: e,
          tab: this.tabName
        });
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "getVideoPosts", [_object.action]))();
    getNextPage(args) {
      if (args.tab === this.tabName) {
        this.pageNumber++;
        this.getVideoPosts();
      }
    }
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="videos-results" {{didUpdate this.getNewVideos @searchTerm}}>
          {{#each this.videos key="id" as |episode|}}
            <AgcTvEpisode
              @episode={{episode}}
              @renderExcerpt={{not this.site.mobileView}}
            />
          {{/each}}
        </div>
      
    */
    {
      "id": "ZDxjw4Pq",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"videos-results\"],[4,[32,0],[[30,0,[\"getNewVideos\"]],[30,1]],null],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"videos\"]]],null]],null],\"id\",[[[1,\"        \"],[8,[32,1],null,[[\"@episode\",\"@renderExcerpt\"],[[30,2],[28,[32,2],[[30,0,[\"site\",\"mobileView\"]]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@searchTerm\",\"episode\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/videos-tab.js",
      "scope": () => [_didUpdate.default, _tvEpisode.default, _truthHelpers.not],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = VideosTab;
});
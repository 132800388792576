define("discourse/plugins/discourse-global-communities/discourse/feature-flags/ai-semantic-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AI_SEARCH_TOAST_SHOWN_KEY = "ai-search-toast-dismissed-count";
  class AiSemanticSearchFeatureFlag {
    static enabledSetting = "agc_ai_search_enabled";
    static groupsSetting = "agc_ai_search_groups";
    static className = "agc-ai-search-enabled";
    static afterEnabled(api) {
      const agcToastsService = api.container.lookup("service:agc-toasts");
      api.onAppEvent("full-page-search:ai-results-toggled", _ref => {
        let {
          enabled
        } = _ref;
        if (!enabled) {
          return;
        }
        const currentToast = document.querySelector(".agc-toast.ai-search-enabled-toast");
        if (currentToast) {
          // We are already showing the toast! Do nothing, and do not increase the shown count
          return;
        }
        let shownCount = parseInt(localStorage.getItem(AI_SEARCH_TOAST_SHOWN_KEY) || 0, 10);
        if (shownCount < 3) {
          localStorage.setItem(AI_SEARCH_TOAST_SHOWN_KEY, shownCount + 1);
          agcToastsService.showAiSearchEnabledToast();
        }
      });
    }
  }
  _exports.default = AiSemanticSearchFeatureFlag;
});
define("discourse/plugins/discourse-global-communities/discourse/services/getMembers", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchMembers = void 0;
  const fetchMembers = async fetchParams => {
    return await (0, _ajax.ajax)(`/members.json?guideCategoryId=all&page=${fetchParams.pageNumber}&term=${fetchParams.searchTerm}`);
  };
  _exports.fetchMembers = fetchMembers;
});
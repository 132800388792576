define("discourse/plugins/discourse-global-communities/discourse/services/getTags", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTags = void 0;
  const getTags = async args => {
    return await (0, _ajax.ajax)(`/tags/filter/search.json?limit=${args.limit}&q=${args.searchTerm}`, {
      contentType: "application/json",
      dataType: "json",
      type: "GET"
    });
  };
  _exports.getTags = getTags;
});
define("discourse/plugins/discourse-global-communities/discourse/components/tab-content", ["exports", "@ember/component", "@glimmer/component", "discourse/plugins/discourse-global-communities/discourse/helpers/improved-search-helpers", "@ember/template-factory"], function (_exports, _component, _component2, _improvedSearchHelpers, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-tab-content-container">
  
    <AgcInfiniteScroll
      @tabName={{@componentName}}
      @searchTerm={{@searchTerm}}
      @resultsCache={{@resultsCache}}
    >
      {{component
        @componentName
        tabName=@componentName
        searchTerm=@searchTerm
        resultsCache=@resultsCache
      }}
  
      {{#if this.showNoResultsWithFilters}}
        <h3 class="agc-search-no-more-results">
          {{i18n "agc.search-tabs.filters.no-results"}}
        </h3>
      {{/if}}
  
    </AgcInfiniteScroll>
  </div>
  */
  {
    "id": "xLgbXaa3",
    "block": "[[[10,0],[14,0,\"agc-tab-content-container\"],[12],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@tabName\",\"@searchTerm\",\"@resultsCache\"],[[30,1],[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n    \"],[46,[30,1],null,[[\"tabName\",\"searchTerm\",\"resultsCache\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showNoResultsWithFilters\"]],[[[1,\"      \"],[10,\"h3\"],[14,0,\"agc-search-no-more-results\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"agc.search-tabs.filters.no-results\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@componentName\",\"@searchTerm\",\"@resultsCache\"],false,[\"div\",\"agc-infinite-scroll\",\"component\",\"if\",\"h3\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/tab-content.hbs",
    "isStrictMode": false
  });
  class TabContent extends _component2.default {
    get resultsHasFilters() {
      return (0, _improvedSearchHelpers.tabHasFilters)(this.args.resultsCache.filterCache);
    }
    get resultsCount() {
      return this.args.resultsCache.results?.length;
    }
    get showNoResultsWithFilters() {
      return this.resultsHasFilters && this.resultsCount === 0;
    }
  }
  _exports.default = TabContent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TabContent);
});
define("discourse/plugins/discourse-global-communities/discourse/components/groups-tab", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/config/app-events-config", "discourse/plugins/discourse-global-communities/discourse/helpers/improved-search-helpers", "discourse/plugins/discourse-global-communities/discourse/services/getGroups", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _appEventsConfig, _improvedSearchHelpers, _getGroups2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="circles-section-cards circles-boxes"
    {{did-update this.getNewGroups @searchTerm}}
  >
    {{#each this.groups as |group|}}
      <Circles::Card @model={{group}} @showMembershipButton={{true}} />
    {{/each}}
  </div>
  */
  {
    "id": "ocQxsRhb",
    "block": "[[[11,0],[24,0,\"circles-section-cards circles-boxes\"],[4,[38,1],[[30,0,[\"getNewGroups\"]],[30,1]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"groups\"]]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@model\",\"@showMembershipButton\"],[[30,2],true]],null],[1,\"\\n\"]],[2]],null],[13]],[\"@searchTerm\",\"group\"],false,[\"div\",\"did-update\",\"each\",\"-track-array\",\"circles/card\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/groups-tab.hbs",
    "isStrictMode": false
  });
  class GroupsTab extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "groups", [_tracking.tracked], function () {
      return [];
    }))();
    #groups = (() => (dt7948.i(this, "groups"), void 0))();
    tabName;
    pageNumber;
    hasReachedLastPage;
    constructor(owner, args) {
      super(owner, args);
      this.tabName = args.tabName;
      const resultsCache = args.resultsCache;
      this.pageNumber = resultsCache.pageNumber;
      this.hasReachedLastPage = resultsCache.hasReachedLastPage;
      if (resultsCache.results) {
        this.groups = resultsCache.results;
      } else {
        this.getGroups();
      }
      this.appEvents.on(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off(_appEventsConfig.SEARCH_LOAD_MORE, this, this.getNextPage);
    }
    getNewGroups() {
      this.groups = [];
      this.pageNumber = 0;
      this.hasReachedLastPage = false;
      this.getGroups();
    }
    static #_6 = (() => dt7948.n(this.prototype, "getNewGroups", [_object.action]))();
    getGroups() {
      const fetchParams = {
        filter: this.search.activeGlobalSearchTerm,
        page: this.pageNumber,
        page_size: this.siteSettings.improved_search_page_size
      };
      const startRequest = Date.now();
      (0, _getGroups2.fetchGroups)(this.store, fetchParams).then(result => {
        const endRequest = Date.now();
        const requestLatency = endRequest - startRequest;
        (0, _improvedSearchHelpers.sendRequestLatencyToRum)(requestLatency, this.tabName);
        this.groups = [...this.groups, ...result.content];
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS, {
          action: "results",
          value: this.groups,
          tab: this.tabName,
          pageNumber: this.pageNumber,
          hasReachedLastPage: result.content.length < this.siteSettings.improved_search_page_size,
          extractAnalyticsKeys: group => group.category_id
        });
      }).catch(e => {
        this.appEvents.trigger(_appEventsConfig.TAB_CONTENTS_ERROR, {
          error: e,
          tab: this.tabName
        });
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "getGroups", [_object.action]))();
    getNextPage(args) {
      if (args.tab === this.tabName) {
        this.pageNumber++;
        this.getGroups();
      }
    }
  }
  _exports.default = GroupsTab;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupsTab);
});
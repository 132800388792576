define("discourse/plugins/discourse-global-communities/discourse/services/agc-improved-search", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcImprovedSearchService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "loadMorePressed", [_tracking.tracked], function () {
      return false;
    }))();
    #loadMorePressed = (() => (dt7948.i(this, "loadMorePressed"), void 0))(); // This service is used to track if "Load More" has been pressed on full page search
    // to block infinite scrolling until this has happened.
  }
  _exports.default = AgcImprovedSearchService;
});
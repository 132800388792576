define("discourse/plugins/discourse-global-communities/discourse/services/getCategories", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCategories = void 0;
  /**
   * Retrieves all categories on the discourse instance.
   *
   * @returns {Promise<*>}  List of all categories.
   */
  const getCategories = async () => {
    return await (0, _ajax.ajax)(`/categories.json`, {
      contentType: "application/json",
      dataType: "json",
      type: "GET"
    });
  };
  _exports.getCategories = getCategories;
});
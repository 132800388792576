define("discourse/plugins/discourse-global-communities/discourse/components/agc/chat/drawer/start-chatting", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Chat::Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
    <navbar.Title @title={{i18n "chat.heading"}} />
    <navbar.Actions as |action|>
      <action.ToggleDrawerButton />
      <action.FullPageButton />
      <action.CloseDrawerButton />
    </navbar.Actions>
  </Chat::Navbar>
  
  {{#if this.chatStateManager.isDrawerExpanded}}
    <div class="chat-drawer-content">
      <Agc::Chat::Pages::StartChatting />
    </div>
  {{/if}}
  */
  {
    "id": "L+OnFxuK",
    "block": "[[[8,[39,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[37,1],[\"chat.heading\"],null]]],null],[1,\"\\n  \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n    \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n    \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"  \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n    \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"navbar\",\"action\"],false,[\"chat/navbar\",\"i18n\",\"if\",\"div\",\"agc/chat/pages/start-chatting\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/agc/chat/drawer/start-chatting.hbs",
    "isStrictMode": false
  });
  class AgcChatDrawerStartChatting extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
  }
  _exports.default = AgcChatDrawerStartChatting;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AgcChatDrawerStartChatting);
});
define("discourse/plugins/discourse-global-communities/discourse/components/idf-session-watched", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.watched}}
    <span class="session-watched">
      {{d-icon "far-circle-check"}}
      <span class="watched-info">
        {{html-safe
          (i18n
            "agc.idf.session_watched"
            date=(format-date this.watched format="medium-with-ago" noTitle=true)
          )
        }}
      </span>
    </span>
  {{else}}
    <span class="unwatched">{{i18n "agc.idf.unwatched"}}</span>
  {{/if}}
  */
  {
    "id": "SuWc3ZAV",
    "block": "[[[41,[30,0,[\"watched\"]],[[[1,\"  \"],[10,1],[14,0,\"session-watched\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"far-circle-check\"],null]],[1,\"\\n    \"],[10,1],[14,0,\"watched-info\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[[28,[37,4],[\"agc.idf.session_watched\"],[[\"date\"],[[28,[37,5],[[30,0,[\"watched\"]]],[[\"format\",\"noTitle\"],[\"medium-with-ago\",true]]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"unwatched\"],[12],[1,[28,[35,4],[\"agc.idf.unwatched\"],null]],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"span\",\"d-icon\",\"html-safe\",\"i18n\",\"format-date\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/idf-session-watched.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component.default {});
});
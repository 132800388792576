define("discourse/plugins/discourse-global-communities/discourse/connectors/full-page-search-above-search-header/improved-search", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ImprovedSearch extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    displayImprovedSearch = this._shouldRender();
    _shouldRender() {
      return this.siteSettings.enable_improved_search;
    }
  }
  _exports.default = ImprovedSearch;
});
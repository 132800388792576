define("discourse/plugins/discourse-global-communities/discourse/connectors/full-page-search-below-results/load-more", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FullPageSearchLoadMore extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcImprovedSearch", [_service.service]))();
    #agcImprovedSearch = (() => (dt7948.i(this, "agcImprovedSearch"), void 0))();
    get show() {
      return this.args.outletArgs.canLoadMore && !this.agcImprovedSearch.loadMorePressed;
    }
    loadMore() {
      this.agcImprovedSearch.loadMorePressed = true;
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <div class="agc-search-load-more">
            <DButton
              class="agc-custom-button agc-search-load-more-button"
              @label="agc.search-tabs.load-more"
              @action={{this.loadMore}}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "EyLVWTx8",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"agc-search-load-more\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"agc-custom-button agc-search-load-more-button\"]],[[\"@label\",\"@action\"],[\"agc.search-tabs.load-more\",[30,0,[\"loadMore\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/full-page-search-below-results/load-more.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FullPageSearchLoadMore;
});
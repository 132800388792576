define("discourse/plugins/discourse-global-communities/discourse/components/tab-header", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-tab-header-container">
    {{#each @tabs as |tab|}}
      <div class="agc-tab">
        <div class="agc-tab-header-label">
          <div
            class="agc-tab-header
              {{if (eq @selectedTab tab.componentName) 'agc-active-tab'}}
              "
            id={{tab.componentName}}
            role="button"
            {{on "click" @tabChangeHandler}}
          >
            {{tab.label}}
          </div>
        </div>
  
        <div class="agc-tab-indicator">
          <div
            class="tab-indicator
              {{if
                (eq @selectedTab tab.componentName)
                'agc-tab-indicator-active'
              }}"
          > </div>
        </div>
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "DoAuSOB+",
    "block": "[[[10,0],[14,0,\"agc-tab-header-container\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"agc-tab\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"agc-tab-header-label\"],[12],[1,\"\\n        \"],[11,0],[16,0,[29,[\"agc-tab-header\\n            \",[52,[28,[37,4],[[30,3],[30,2,[\"componentName\"]]],null],\"agc-active-tab\"],\"\\n            \"]]],[16,1,[30,2,[\"componentName\"]]],[24,\"role\",\"button\"],[4,[38,5],[\"click\",[30,4]],null],[12],[1,\"\\n          \"],[1,[30,2,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"agc-tab-indicator\"],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"tab-indicator\\n            \",[52,[28,[37,4],[[30,3],[30,2,[\"componentName\"]]],null],\"agc-tab-indicator-active\"]]]],[12],[1,\" \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"@tabs\",\"tab\",\"@selectedTab\",\"@tabChangeHandler\"],false,[\"div\",\"each\",\"-track-array\",\"if\",\"eq\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/tab-header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component.default {});
});
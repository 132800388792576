define("discourse/plugins/discourse-global-communities/discourse/templates/admin/plugins-agc-program-pillars", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{i18n "admin.agc.program_pillars.title"}}</h1>
  
  <div class="admin-program-pillar-tabs">
    {{#each this.programPillarsTabs as |tag|}}
      <button
        data-id={{tag.id}}
        class="tab {{if (eq this.modelToEdit tag.id) 'selected'}}"
        {{on "click" (fn this.updateModelToEdit tag.id)}}
        type="button"
      >
        <span class="admin-program-pillar__enabled">
          {{d-icon (if tag.enabled "check" "xmark")}}
          {{tag.name}}
        </span>
      </button>
    {{/each}}
  </div>
  
  {{#each this.model as |programPillar|}}
    <AdminProgramPillarForm
      @isEditing={{eq this.modelToEdit programPillar.id}}
      @model={{programPillar}}
      @save={{this.saveEdit}}
      @cancel={{this.removeModelToEdit}}
      @delete={{this.deleteProgramPillar}}
    />
  {{/each}}
  
  {{#if this.creatingNew}}
    <AdminProgramPillarForm
      @isEditing={{true}}
      @cancel={{this.toggleCreating}}
      @save={{this.createNew}}
    />
  {{/if}}
  
  {{#if (and (not this.creatingNew) (not this.modelToEdit))}}
    <DButton
      class="btn-primary"
      data-selector="admin-program-pillars-new-btn"
      @icon="plus"
      @label="admin.agc.program_pillars.add_region"
      @action={{this.toggleCreating}}
    />
  {{/if}}
  */
  {
    "id": "3OHi/zdV",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,1],[\"admin.agc.program_pillars.title\"],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-program-pillar-tabs\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"programPillarsTabs\"]]],null]],null],null,[[[1,\"    \"],[11,\"button\"],[16,\"data-id\",[30,1,[\"id\"]]],[16,0,[29,[\"tab \",[52,[28,[37,7],[[30,0,[\"modelToEdit\"]],[30,1,[\"id\"]]],null],\"selected\"]]]],[24,4,\"button\"],[4,[38,8],[\"click\",[28,[37,9],[[30,0,[\"updateModelToEdit\"]],[30,1,[\"id\"]]],null]],null],[12],[1,\"\\n      \"],[10,1],[14,0,\"admin-program-pillar__enabled\"],[12],[1,\"\\n        \"],[1,[28,[35,11],[[52,[30,1,[\"enabled\"]],\"check\",\"xmark\"]],null]],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"  \"],[8,[39,12],null,[[\"@isEditing\",\"@model\",\"@save\",\"@cancel\",\"@delete\"],[[28,[37,7],[[30,0,[\"modelToEdit\"]],[30,2,[\"id\"]]],null],[30,2],[30,0,[\"saveEdit\"]],[30,0,[\"removeModelToEdit\"]],[30,0,[\"deleteProgramPillar\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[41,[30,0,[\"creatingNew\"]],[[[1,\"  \"],[8,[39,12],null,[[\"@isEditing\",\"@cancel\",\"@save\"],[true,[30,0,[\"toggleCreating\"]],[30,0,[\"createNew\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,13],[[28,[37,14],[[30,0,[\"creatingNew\"]]],null],[28,[37,14],[[30,0,[\"modelToEdit\"]]],null]],null],[[[1,\"  \"],[8,[39,15],[[24,0,\"btn-primary\"],[24,\"data-selector\",\"admin-program-pillars-new-btn\"]],[[\"@icon\",\"@label\",\"@action\"],[\"plus\",\"admin.agc.program_pillars.add_region\",[30,0,[\"toggleCreating\"]]]],null],[1,\"\\n\"]],[]],null]],[\"tag\",\"programPillar\"],false,[\"h1\",\"i18n\",\"div\",\"each\",\"-track-array\",\"button\",\"if\",\"eq\",\"on\",\"fn\",\"span\",\"d-icon\",\"admin-program-pillar-form\",\"and\",\"not\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/admin/plugins-agc-program-pillars.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/components/tab-error", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-tab-error-container">
    <div class="agc-tab-error-alert">
      {{this.errorMessage}}
    </div>
  </div>
  */
  {
    "id": "g2RlToru",
    "block": "[[[10,0],[14,0,\"agc-tab-error-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"agc-tab-error-alert\"],[12],[1,\"\\n    \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/tab-error.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component.default {});
});
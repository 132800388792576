define("discourse/plugins/discourse-global-communities/discourse/templates/user/activity", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{body-class "user-activity-page"}}
  
  <section class="user-secondary-navigation">
    <Agc::UserProfile::ActivityNav @model={{this.model}} />
  </section>
  
  <section class="user-content">
    {{outlet}}
  </section>
  */
  {
    "id": "xkplsqc8",
    "block": "[[[1,[28,[35,0],[\"user-activity-page\"],null]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"user-secondary-navigation\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"user-content\"],[12],[1,\"\\n  \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"section\",\"agc/user-profile/activity-nav\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/user/activity.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-activity-bottom/helpful-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#link-to route="userActivity.helpful"}}
    {{d-icon "circle-check"}}
    {{i18n "discourse_gc.helpful_answer"}}
  {{/link-to}}
  */
  {
    "id": "yHD8MKLZ",
    "block": "[[[6,[39,0],null,[[\"route\"],[\"userActivity.helpful\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],[\"circle-check\"],null]],[1,\"\\n  \"],[1,[28,[35,2],[\"discourse_gc.helpful_answer\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-activity-bottom/helpful-list.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/components/gc-circle-letter-logo", ["exports", "@ember/component", "@glimmer/component", "discourse/plugins/discourse-global-communities/discourse/helpers/circles-logo", "@ember/template-factory"], function (_exports, _component, _component2, _circlesLogo, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style={{this.avatarStyle}}>
    {{#if this.letter}}
      {{html-safe this.imgElement}}
    {{/if}}
  </div>
  */
  {
    "id": "z0SsxkVJ",
    "block": "[[[10,0],[15,5,[30,0,[\"avatarStyle\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"letter\"]],[[[1,\"    \"],[1,[28,[35,2],[[30,0,[\"imgElement\"]]],null]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/gc-circle-letter-logo.hbs",
    "isStrictMode": false
  });
  class GcCircleLetterLogoComponent extends _component2.default {
    #letterHelper;
    constructor() {
      super(...arguments);
      this.#letterHelper = new _circlesLogo.GcCircleLetterLogoHelper(this.args.model, {
        imageSize: this.args.imageSize,
        className: this.args.class
      });
    }
    get avatarStyle() {
      return this.#letterHelper.avatarStyle;
    }
    get letter() {
      return this.#letterHelper.letter;
    }
    get imgElement() {
      return this.#letterHelper.imgElement;
    }
  }
  _exports.default = GcCircleLetterLogoComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GcCircleLetterLogoComponent);
});
define("discourse/plugins/discourse-global-communities/discourse/components/idf-session-watch-now", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="session-watch-now">
    <span class="background"></span>
    <span class="foreground">
      {{d-icon "play"}}
      <span class="text">
        {{i18n "agc.idf.session_watch_now"}}
      </span>
    </span>
  </div>
  */
  {
    "id": "C5S2F+Vx",
    "block": "[[[10,0],[14,0,\"session-watch-now\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"background\"],[12],[13],[1,\"\\n  \"],[10,1],[14,0,\"foreground\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"play\"],null]],[1,\"\\n    \"],[10,1],[14,0,\"text\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"agc.idf.session_watch_now\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"span\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/components/idf-session-watch-now.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component.default {});
});
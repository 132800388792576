define("discourse/plugins/discourse-global-communities/discourse/services/getGroups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchGroups = void 0;
  /**
   * Retrieves the groups/circles using the store service. On the background this is calling the /circles.json request.
   * See this meta post for reference: https://meta.discourse.org/t/search-engine-enhancements/249509/62?u=tayrogel
   *
   * interface Params {
   *     filter?: string
   *     page?: number
   *     view_type?: string
   *     page_size?: number
   * }
   *
   * @param  {Object} store
   * @param  {Params} fetchParams Params used to filter the response.
   * @return {Promise<*>}   List of circles filtered by fetchParams.
   */

  const fetchGroups = async (store, fetchParams) => {
    return store.findAll("circle", fetchParams);
  };
  _exports.fetchGroups = fetchGroups;
});
define("discourse/plugins/discourse-global-communities/discourse/initializers/admin-menu-actions", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/components/modal/manage-featured"], function (_exports, _ajax, _ajaxError, _pluginApi, _manageFeatured) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-admin-menu-actions",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const modalService = container.lookup("service:modal");
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        if (siteSettings.enable_admin_manage_featured) {
          api.addTopicAdminMenuButton(topic => {
            const staff = api.getCurrentUser()?.staff;
            if (!topic.isPrivateMessage && staff) {
              return {
                action: () => {
                  modalService.show(_manageFeatured.default, {
                    model: topic
                  });
                },
                icon: "shield-halved",
                className: "btn-default",
                label: "agc.manage_featured"
              };
            }
          });
        }
        if (siteSettings.enable_admin_mark_question) {
          api.addTopicAdminMenuButton(topic => {
            const canCreateTopic = api.getCurrentUser()?.can_create_topic;
            if (!topic.isPrivateMessage && canCreateTopic) {
              return {
                action: () => {
                  updateQuestionTopic(topic);
                },
                icon: "shield-halved",
                className: "btn-default",
                label: topic.get("replies_can_be_marked_question") ? "agc.disable_question" : "agc.mark_question"
              };
            }
          });
        }
      });
      function updateQuestionTopic(topic) {
        const data = {
          topic_id: topic.id,
          make_question: !topic.replies_can_be_marked_question
        };
        (0, _ajax.ajax)(`/question-topic.json`, {
          method: "PUT",
          data
        }).then(result => {
          if (result) {
            topic.set("replies_can_be_marked_question", result.is_question);
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  };
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc/search/menu", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/search-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert, _service, _searchMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcSearchMenu extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    fillInFromSearchQueryParams() {
      if (this.router.currentRouteName !== "full-page-search") {
        return;
      }
      const urlParams = new URLSearchParams(location.search);
      const searchTerm = urlParams.get("q");
      if (searchTerm?.length) {
        this.search.activeGlobalSearchTerm = searchTerm;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "fillInFromSearchQueryParams", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="search-menu" {{didInsert this.fillInFromSearchQueryParams}}>
          <SearchMenu />
        </div>
      
    */
    {
      "id": "hdsV/bu6",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"search-menu\"],[4,[32,0],[[30,0,[\"fillInFromSearchQueryParams\"]]],null],[12],[1,\"\\n      \"],[8,[32,1],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/search/menu.js",
      "scope": () => [_didInsert.default, _searchMenu.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcSearchMenu;
});
define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/composer-fields-below/file-upload", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="composer-upload">
    {{#if this.allowUpload}}
      {{d-button
        class="mobile-file-upload"
        icon="upload-squid"
        label="composer.upload"
        ariaLabel="composer.upload_action"
        disabled=this.isUploading
        action=(route-action "showUploadSelector")
      }}
    {{/if}}
  </div>
  */
  {
    "id": "hsQDVto7",
    "block": "[[[10,0],[14,0,\"composer-upload\"],[12],[1,\"\\n\"],[41,[30,0,[\"allowUpload\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"class\",\"icon\",\"label\",\"ariaLabel\",\"disabled\",\"action\"],[\"mobile-file-upload\",\"upload-squid\",\"composer.upload\",\"composer.upload_action\",[30,0,[\"isUploading\"]],[28,[37,3],[\"showUploadSelector\"],null]]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"if\",\"d-button\",\"route-action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/composer-fields-below/file-upload.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/helpful-button", ["exports", "@glimmer/component", "@ember/object", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dButton, _concatClass, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcPostMenuHelpfulButton extends _component.default {
    static shouldRender(args) {
      return args.post.can_mark_helpful || args.post.is_helpful;
    }
    get disabled() {
      return !this.args.post.can_mark_helpful;
    }
    async toggleHelpfulAnswer() {
      if (this.disabled) {
        return;
      }
      const post = this.args.post;
      const data = {
        post_id: post.id,
        make_helpful: !post.is_helpful
      };
      post.set("is_helpful", !post.is_helpful);
      await (0, _ajax.ajax)(`/helpful-post.json`, {
        method: "PUT",
        data
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_ = (() => dt7948.n(this.prototype, "toggleHelpfulAnswer", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class={{concatClass
            "helpful-button"
            (if @post.is_helpful "is-helpful")
            (if this.disabled "disabled")
          }}
          ...attributes
          disabled={{this.disabled}}
          @action={{this.toggleHelpfulAnswer}}
          @icon="circle-check"
          @label="discourse_gc.helpful_answer"
        />
      
    */
    {
      "id": "Urb1kbpM",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"helpful-button\",[52,[30,1,[\"is_helpful\"]],\"is-helpful\"],[52,[30,0,[\"disabled\"]],\"disabled\"]],null]],[17,2],[16,\"disabled\",[30,0,[\"disabled\"]]]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"toggleHelpfulAnswer\"]],\"circle-check\",\"discourse_gc.helpful_answer\"]],null],[1,\"\\n  \"]],[\"@post\",\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/helpful-button.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcPostMenuHelpfulButton;
});
define("discourse/plugins/discourse-global-communities/discourse/templates/components/categories-topic-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topics}}
    {{#each this.topics as |t|}}
      {{topic-list-item topic=t}}
    {{/each}}
  {{else}}
    <div class="no-topics">
      <h3>{{i18n "agc.topic-list.no-topics"}}</h3>
    </div>
  {{/if}}
  */
  {
    "id": "lRV6kppm",
    "block": "[[[41,[30,0,[\"topics\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,3],null,[[\"topic\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"  \"],[10,0],[14,0,\"no-topics\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,6],[\"agc.topic-list.no-topics\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"t\"],false,[\"if\",\"each\",\"-track-array\",\"topic-list-item\",\"div\",\"h3\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/categories-topic-list.hbs",
    "isStrictMode": false
  });
});
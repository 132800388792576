define("discourse/plugins/discourse-global-communities/discourse/components/notification-preferences-toggle", ["exports", "@glimmer/component", "@ember/service", "discourse/lib/notification-levels", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/preference-toggle", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _notificationLevels, _discourseI18n, _preferenceToggle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TOGGLE_TYPE_CONFIGS = _exports.PARENT_MOBILE_PUSH_USER_OPTION = _exports.PARENT_EMAIL_USER_OPTION = _exports.NOTIFICATIONS_PREFERENCE_EVENT_TYPE = void 0;
  const NOTIFICATION_WATCHING_LEVEL = _notificationLevels.topicLevels.find(tl => tl.key === "watching")?.id;
  const NOTIFICATION_REGULAR_LEVEL = _notificationLevels.topicLevels.find(tl => tl.key === "regular")?.id;
  const EMAIL_LEVEL_ALWAYS = 0;
  const EMAIL_LEVEL_NEVER = 2;
  const EMAIL_FREQUENCY_NEVER = "never";
  const EMAIL_FREQUENCY_WHEN_AWAY = "when_away";
  const EMAIL_FREQUENCY_ALWAYS = "always";
  const EMAIL_FREQUENCY_WEEKLY = "weekly";
  const CHAT_SOUND_BELL = "bell";
  const CHAT_SOUND_DING = "ding";
  const CHAT_SOUND_NONE = "";
  const TOGGLE_TYPE_CONFIGS = _exports.TOGGLE_TYPE_CONFIGS = {
    push: {
      enabled: true,
      disabled: false,
      values: [true, false]
    },
    email: {
      enabled: EMAIL_FREQUENCY_ALWAYS,
      disabled: EMAIL_FREQUENCY_NEVER,
      values: [EMAIL_FREQUENCY_ALWAYS, EMAIL_FREQUENCY_WEEKLY, EMAIL_LEVEL_NEVER]
    },
    email_general: {
      enabled: EMAIL_LEVEL_ALWAYS,
      disabled: EMAIL_LEVEL_NEVER,
      values: [EMAIL_LEVEL_ALWAYS, EMAIL_LEVEL_NEVER]
    },
    chat_email: {
      enabled: EMAIL_FREQUENCY_WHEN_AWAY,
      disabled: EMAIL_FREQUENCY_NEVER,
      values: [EMAIL_FREQUENCY_WHEN_AWAY, EMAIL_FREQUENCY_NEVER]
    },
    sound: {
      enabled: CHAT_SOUND_BELL,
      disabled: CHAT_SOUND_NONE,
      values: [CHAT_SOUND_DING, CHAT_SOUND_NONE, CHAT_SOUND_BELL]
    },
    follow: {
      enabled: NOTIFICATION_WATCHING_LEVEL,
      disabled: NOTIFICATION_REGULAR_LEVEL,
      values: [NOTIFICATION_WATCHING_LEVEL, NOTIFICATION_REGULAR_LEVEL]
    },
    follow_merged_topics: {
      enabled: true,
      disabled: false,
      values: [true, false]
    }
  };
  const PARENT_EMAIL_USER_OPTION = _exports.PARENT_EMAIL_USER_OPTION = "email_level";
  const PARENT_MOBILE_PUSH_USER_OPTION = _exports.PARENT_MOBILE_PUSH_USER_OPTION = "allow_push_notifications";
  const NOTIFICATIONS_PREFERENCE_EVENT_TYPE = _exports.NOTIFICATIONS_PREFERENCE_EVENT_TYPE = "notifications_preference_change";
  class NotificationPreferencesToggle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    pushToggleConfig = (() => TOGGLE_TYPE_CONFIGS.push)();
    soundToggleConfig = (() => TOGGLE_TYPE_CONFIGS.sound)();
    eventType = (() => NOTIFICATIONS_PREFERENCE_EVENT_TYPE || this.args.eventType)();
    frequencyOptions = (() => [{
      id: EMAIL_FREQUENCY_ALWAYS,
      label: (0, _discourseI18n.i18n)("notifications.preferences.individual")
    }, {
      id: EMAIL_FREQUENCY_WEEKLY,
      label: (0, _discourseI18n.i18n)("notifications.preferences.weekly")
    }])();
    chatSoundOptions = (() => [{
      id: CHAT_SOUND_BELL,
      label: (0, _discourseI18n.i18n)("notifications.preferences.bell")
    }, {
      id: CHAT_SOUND_DING,
      label: (0, _discourseI18n.i18n)("notifications.preferences.ding")
    }])();
    get pushUserOptionField() {
      if (!this.args.notificationType) {
        return `allow_${this.site.desktopView ? "browser" : "push"}_notifications`;
      }
      return `allow_${this.args.notificationType}_${this.site.desktopView ? "browser" : "push"}_notifications`;
    }
    get emailUserOptionField() {
      if (this.args.notificationType === "chat") {
        return "chat_email_frequency";
      } else if (!this.args.notificationType) {
        return "email_level";
      }
      return `${this.args.notificationType}_email_notification_level`;
    }
    get emailToggleConfig() {
      if (this.args.notificationType === "chat") {
        return TOGGLE_TYPE_CONFIGS.chat_email;
      } else if (!this.args.notificationType) {
        return TOGGLE_TYPE_CONFIGS.email_general;
      }
      return TOGGLE_TYPE_CONFIGS.email;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless @hidePushRow}}
          <PreferenceToggle
            @icon={{@pushIcon}}
            @label={{@pushLabel}}
            @subtitle={{@pushSubtitle}}
            @shouldShowSubtitle={{true}}
            @shouldShowSubToggle={{@showSound}}
            @hideRadioButtons={{true}}
            @outletArgs={{@outletArgs}}
            @subToggleLabel={{@subToggleLabel}}
            @subToggleSubtitle={{@subToggleSubtitle}}
            @userOptionField={{this.pushUserOptionField}}
            @subToggleUserOptionField={{@subToggleUserOptionField}}
            @subToggleOptions={{this.chatSoundOptions}}
            @toggleConfig={{this.pushToggleConfig}}
            @subToggleConfig={{this.soundToggleConfig}}
            @eventType={{this.eventType}}
            @isDisabled={{@isPushDisabled}}
            @toggleState={{@pushToggleState}}
            @isParentToggle={{@isParentToggle}}
            @appEvent="push"
            @overrideAction={{@overridePushAction}}
            @afterToggleOn={{@afterTogglePushOn}}
          />
        {{/unless}}
        <PreferenceToggle
          @subtitleClass={{@subtitleClass}}
          @icon={{@emailIcon}}
          @label={{i18n "notifications.preferences.email"}}
          @subtitle={{@emailSubtitle}}
          @shouldShowSubtitle={{@shouldShowSubtitle}}
          @outletArgs={{@outletArgs}}
          @hideRadioButtons={{@hideFrequency}}
          @frequencyLabel="notifications.preferences.email_frequency"
          @userOptionField={{this.emailUserOptionField}}
          @options={{this.frequencyOptions}}
          @toggleConfig={{this.emailToggleConfig}}
          @eventType={{this.eventType}}
          @isDisabled={{@isEmailDisabled}}
          @toggleState={{@emailToggleState}}
          @isParentToggle={{@isParentToggle}}
          @appEvent="email"
        />
      
    */
    {
      "id": "JUV07j1E",
      "block": "[[[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"      \"],[8,[32,0],null,[[\"@icon\",\"@label\",\"@subtitle\",\"@shouldShowSubtitle\",\"@shouldShowSubToggle\",\"@hideRadioButtons\",\"@outletArgs\",\"@subToggleLabel\",\"@subToggleSubtitle\",\"@userOptionField\",\"@subToggleUserOptionField\",\"@subToggleOptions\",\"@toggleConfig\",\"@subToggleConfig\",\"@eventType\",\"@isDisabled\",\"@toggleState\",\"@isParentToggle\",\"@appEvent\",\"@overrideAction\",\"@afterToggleOn\"],[[30,2],[30,3],[30,4],true,[30,5],true,[30,6],[30,7],[30,8],[30,0,[\"pushUserOptionField\"]],[30,9],[30,0,[\"chatSoundOptions\"]],[30,0,[\"pushToggleConfig\"]],[30,0,[\"soundToggleConfig\"]],[30,0,[\"eventType\"]],[30,10],[30,11],[30,12],\"push\",[30,13],[30,14]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[32,0],null,[[\"@subtitleClass\",\"@icon\",\"@label\",\"@subtitle\",\"@shouldShowSubtitle\",\"@outletArgs\",\"@hideRadioButtons\",\"@frequencyLabel\",\"@userOptionField\",\"@options\",\"@toggleConfig\",\"@eventType\",\"@isDisabled\",\"@toggleState\",\"@isParentToggle\",\"@appEvent\"],[[30,15],[30,16],[28,[32,1],[\"notifications.preferences.email\"],null],[30,17],[30,18],[30,6],[30,19],\"notifications.preferences.email_frequency\",[30,0,[\"emailUserOptionField\"]],[30,0,[\"frequencyOptions\"]],[30,0,[\"emailToggleConfig\"]],[30,0,[\"eventType\"]],[30,20],[30,21],[30,12],\"email\"]],null],[1,\"\\n  \"]],[\"@hidePushRow\",\"@pushIcon\",\"@pushLabel\",\"@pushSubtitle\",\"@showSound\",\"@outletArgs\",\"@subToggleLabel\",\"@subToggleSubtitle\",\"@subToggleUserOptionField\",\"@isPushDisabled\",\"@pushToggleState\",\"@isParentToggle\",\"@overridePushAction\",\"@afterTogglePushOn\",\"@subtitleClass\",\"@emailIcon\",\"@emailSubtitle\",\"@shouldShowSubtitle\",\"@hideFrequency\",\"@isEmailDisabled\",\"@emailToggleState\"],false,[\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/notification-preferences-toggle.js",
      "scope": () => [_preferenceToggle.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = NotificationPreferencesToggle;
});
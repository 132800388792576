define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/full-page-search-filters/search-advanced-options", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.displaySearchAdvancedOptions}}
    {{#if this.displayAiSearch}}
      <AiFullPageSearch
        @sortOrder={{@outletArgs.sortOrder}}
        @searchTerm={{@outletArgs.searchTerm}}
        @searchType={{@outletArgs.type}}
        @addSearchResults={{@outletArgs.addSearchResults}}
      />
    {{/if}}
    <AgcSearchFilters
      @searchTerm={{@outletArgs.searchTerm}}
      @onChangeSearchTerm={{@outletArgs.onChangeSearchTerm}}
      @search={{@outletArgs.search}}
      @searchButtonDisabled={{@outletArgs.searchButtonDisabled}}
      @expandFilters={{@outletArgs.expandFilters}}
      @sortOrder={{@outletArgs.sortOrder}}
      @sortOrderOptions={{@outletArgs.sortOrderOptions}}
      @setSortOrder={{@outletArgs.setSortOrder}}
      @resultCount={{@outletArgs.resultCount}}
    />
  {{/if}}
  */
  {
    "id": "qI2fxZYU",
    "block": "[[[41,[30,0,[\"displaySearchAdvancedOptions\"]],[[[41,[30,0,[\"displayAiSearch\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@sortOrder\",\"@searchTerm\",\"@searchType\",\"@addSearchResults\"],[[30,1,[\"sortOrder\"]],[30,1,[\"searchTerm\"]],[30,1,[\"type\"]],[30,1,[\"addSearchResults\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,2],null,[[\"@searchTerm\",\"@onChangeSearchTerm\",\"@search\",\"@searchButtonDisabled\",\"@expandFilters\",\"@sortOrder\",\"@sortOrderOptions\",\"@setSortOrder\",\"@resultCount\"],[[30,1,[\"searchTerm\"]],[30,1,[\"onChangeSearchTerm\"]],[30,1,[\"search\"]],[30,1,[\"searchButtonDisabled\"]],[30,1,[\"expandFilters\"]],[30,1,[\"sortOrder\"]],[30,1,[\"sortOrderOptions\"]],[30,1,[\"setSortOrder\"]],[30,1,[\"resultCount\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"ai-full-page-search\",\"agc-search-filters\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/full-page-search-filters/search-advanced-options.hbs",
    "isStrictMode": false
  });
});
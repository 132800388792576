define("discourse/plugins/discourse-global-communities/discourse/connectors/search-menu-before-term-input/search-icon", ["exports", "@glimmer/component", "@ember/object", "discourse/components/d-button", "discourse/components/search-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dButton, _searchMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchMenuIcon extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="search-icon"
          @icon="magnifying-glass"
          @title="search.title"
          @action={{this.open}}
        />
      
    */
    {
      "id": "DRk1yC54",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"search-icon\"]],[[\"@icon\",\"@title\",\"@action\"],[\"magnifying-glass\",\"search.title\",[30,0,[\"open\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/search-menu-before-term-input/search-icon.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
    open() {
      this.args.outletArgs.openSearchMenu();
      (0, _searchMenu.focusSearchInput)();
    }
    static #_2 = (() => dt7948.n(this.prototype, "open", [_object.action]))();
  }
  _exports.default = SearchMenuIcon;
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/saved-post-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _concatClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcPostMenuSavedPostButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcBookmarks", [_service.service]))();
    #agcBookmarks = (() => (dt7948.i(this, "agcBookmarks"), void 0))();
    get isSaved() {
      return this.args.post.bookmarked;
    }
    agcSavePost() {
      this.agcBookmarks.bookmarkPost(this.args.post.topic, this.args.post);
    }
    static #_2 = (() => dt7948.n(this.prototype, "agcSavePost", [_object.action]))();
    agcUnsavePost() {
      this.agcBookmarks.clearBookmarkForPost(this.args.post);
    }
    static #_3 = (() => dt7948.n(this.prototype, "agcUnsavePost", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class={{concatClass "save-post-btn" (if this.isSaved "-unsave" "-save")}}
          ...attributes
          @action={{if this.isSaved this.agcUnsavePost this.agcSavePost}}
          @icon={{if this.isSaved "unbookmark-squid" "bookmark-squid"}}
          @title={{if this.isSaved "saved_posts.unsave" "saved_posts.save"}}
        />
      
    */
    {
      "id": "bNkpFkvh",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"save-post-btn\",[52,[30,0,[\"isSaved\"]],\"-unsave\",\"-save\"]],null]],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[52,[30,0,[\"isSaved\"]],[30,0,[\"agcUnsavePost\"]],[30,0,[\"agcSavePost\"]]],[52,[30,0,[\"isSaved\"]],\"unbookmark-squid\",\"bookmark-squid\"],[52,[30,0,[\"isSaved\"]],\"saved_posts.unsave\",\"saved_posts.save\"]]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/post/menu/saved-post-button.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcPostMenuSavedPostButton;
});
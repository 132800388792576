define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/panel-body-bottom/agc-panel-bottom-buttons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="agc-panel-body-bottom">
    <a class="agc-see-all" href {{action "onClick"}}>{{i18n
        "notifications.see_all"
      }}</a>
    <div
      role="button"
      id="agc-notifications-dismiss"
      class={{if @outletArgs.showDismiss "dismiss-enabled" "dismiss-disabled"}}
      {{on "click" @outletArgs.dismissButtonClick}}
    >
      {{i18n "notifications.dismiss"}}
    </div>
  </div>
  */
  {
    "id": "AIZk7Fv2",
    "block": "[[[10,0],[14,0,\"agc-panel-body-bottom\"],[12],[1,\"\\n  \"],[11,3],[24,0,\"agc-see-all\"],[24,6,\"\"],[4,[38,2],[[30,0],\"onClick\"],null],[12],[1,[28,[35,3],[\"notifications.see_all\"],null]],[13],[1,\"\\n  \"],[11,0],[24,\"role\",\"button\"],[24,1,\"agc-notifications-dismiss\"],[16,0,[52,[30,1,[\"showDismiss\"]],\"dismiss-enabled\",\"dismiss-disabled\"]],[4,[38,5],[\"click\",[30,1,[\"dismissButtonClick\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"notifications.dismiss\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@outletArgs\"],false,[\"div\",\"a\",\"action\",\"i18n\",\"if\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/panel-body-bottom/agc-panel-bottom-buttons.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-global-communities/discourse/raw-templates/list/topic-list-item", ["exports", "discourse/lib/raw-handlebars", "discourse/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <td class=\"bulk-select topic-list-data\">\n    <label for=\"bulk-select-" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.id", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 28
          },
          "end": {
            "line": 3,
            "column": 40
          }
        }
      })) + "\">\n      <input type=\"checkbox\" class=\"bulk-select\" id=\"bulk-select-" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.id", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 65
          },
          "end": {
            "line": 4,
            "column": 77
          }
        }
      })) + "\">\n    </label>\n  </td>\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic-list-item-expandable-excerpt", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 16,
            "column": 6
          },
          "end": {
            "line": 16,
            "column": 62
          }
        }
      })) + "\n";
    },
    "5": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "webinar-list-item", {
        "name": "raw",
        "hash": {
          "webinarTime": "webinarTime",
          "registrationOpen": "registrationOpen",
          "isAttendee": "isAttendee",
          "canRegister": "canRegister",
          "webinarStarted": "webinarStarted",
          "webinarEnded": "webinarEnded",
          "webinar": "webinar"
        },
        "hashTypes": {
          "webinarTime": "PathExpression",
          "registrationOpen": "PathExpression",
          "isAttendee": "PathExpression",
          "canRegister": "PathExpression",
          "webinarStarted": "PathExpression",
          "webinarEnded": "PathExpression",
          "webinar": "PathExpression"
        },
        "hashContexts": {
          "webinarTime": depth0,
          "registrationOpen": depth0,
          "isAttendee": depth0,
          "canRegister": depth0,
          "webinarStarted": depth0,
          "webinarEnded": depth0,
          "webinar": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 20,
            "column": 6
          },
          "end": {
            "line": 29,
            "column": 8
          }
        }
      })) + "\n";
    },
    "7": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "    <div class=\"topic-list-featured-posters\">\n" + ((stack1 = lookupProperty(helpers, "each").call(depth0 != null ? depth0 : container.nullContext || {}, "poster", "in", "topic.featuredUsers", {
        "name": "each",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(8, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression", "CommentStatement", "PathExpression"],
        "contexts": [depth0, depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 37,
            "column": 6
          },
          "end": {
            "line": 46,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + "    </div>\n";
    },
    "8": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "poster.moreCount", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(9, data, 0),
        "inverse": container.program(11, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 38,
            "column": 8
          },
          "end": {
            "line": 45,
            "column": 15
          }
        }
      })) != null ? stack1 : "";
    },
    "9": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "          <a class=\"posters-more-count\">" + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "poster.moreCount", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 39,
            "column": 40
          },
          "end": {
            "line": 39,
            "column": 60
          }
        }
      })) + "</a>\n";
    },
    "11": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "          <a href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "poster.user.path", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 41,
            "column": 19
          },
          "end": {
            "line": 41,
            "column": 39
          }
        }
      })) + "\" data-user-card=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "poster.user.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 41,
            "column": 57
          },
          "end": {
            "line": 41,
            "column": 81
          }
        }
      })) + "\"\n             class=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "poster.extraClasses", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 42,
            "column": 20
          },
          "end": {
            "line": 42,
            "column": 43
          }
        }
      })) + "\">" + alias2((lookupProperty(helpers, "avatar") || depth0 && lookupProperty(depth0, "avatar") || container.hooks.helperMissing).call(alias1, "poster", {
        "name": "avatar",
        "hash": {
          "imageSize": "small",
          "namePath": "user.name",
          "usernamePath": "user.username",
          "avatarTemplatePath": "user.avatar_template"
        },
        "hashTypes": {
          "imageSize": "StringLiteral",
          "namePath": "StringLiteral",
          "usernamePath": "StringLiteral",
          "avatarTemplatePath": "StringLiteral"
        },
        "hashContexts": {
          "imageSize": depth0,
          "namePath": depth0,
          "usernamePath": depth0,
          "avatarTemplatePath": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 42,
            "column": 45
          },
          "end": {
            "line": 44,
            "column": 73
          }
        }
      })) + "</a>\n";
    },
    "13": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "    <div class=\"mobile-only\">\n      <span class=\"divider-text\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "agc.topic-list.posted-in", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 51,
            "column": 33
          },
          "end": {
            "line": 51,
            "column": 68
          }
        }
      })) + " </span> " + alias3((lookupProperty(helpers, "category-link") || depth0 && lookupProperty(depth0, "category-link") || alias2).call(alias1, "topic.category", {
        "name": "category-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 51,
            "column": 77
          },
          "end": {
            "line": 51,
            "column": 109
          }
        }
      })) + "\n    </div>\n";
    },
    "15": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "    <div class=\"tag-wrapper\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.expert_post_group_names", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(16, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 56,
            "column": 6
          },
          "end": {
            "line": 58,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.is_category_expert_question", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(18, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 60,
            "column": 6
          },
          "end": {
            "line": 70,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "    </div>\n";
    },
    "16": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "        " + container.escapeExpression((lookupProperty(helpers, "category-expert-topic-list-pills") || depth0 && lookupProperty(depth0, "category-expert-topic-list-pills") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic", "amazonianGroupName", {
        "name": "category-expert-topic-list-pills",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression", "PathExpression"],
        "contexts": [depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 57,
            "column": 8
          },
          "end": {
            "line": 57,
            "column": 69
          }
        }
      })) + "\n";
    },
    "18": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        " + container.escapeExpression((lookupProperty(helpers, "agc-category-expert-question-indicator") || depth0 && lookupProperty(depth0, "agc-category-expert-question-indicator") || container.hooks.helperMissing).call(alias1, "topic", "currentUser", {
        "name": "agc-category-expert-question-indicator",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression", "PathExpression"],
        "contexts": [depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 61,
            "column": 8
          },
          "end": {
            "line": 61,
            "column": 68
          }
        }
      })) + "\n\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "currentUser.staff", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(19, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 63,
            "column": 8
          },
          "end": {
            "line": 69,
            "column": 15
          }
        }
      })) != null ? stack1 : "");
    },
    "19": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.needs_category_expert_post_approval", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(20, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 64,
            "column": 10
          },
          "end": {
            "line": 68,
            "column": 17
          }
        }
      })) != null ? stack1 : "";
    },
    "20": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "            <a href=\"/search?q=with:unapproved_ce_post\" class=\"topic-list-category-expert-needs-approval\">\n              " + container.escapeExpression((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "category_experts.topic_list.needs_approval", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 66,
            "column": 14
          },
          "end": {
            "line": 66,
            "column": 67
          }
        }
      })) + "\n            </a>\n";
    },
    "22": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "tagsOrContentType", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(23, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 72,
            "column": 2
          },
          "end": {
            "line": 83,
            "column": 2
          }
        }
      })) != null ? stack1 : "";
    },
    "23": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "    <div class=\"tag-wrapper\">\n      <span class=\"tag-title\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "agc.topic-list.tags", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 74,
            "column": 30
          },
          "end": {
            "line": 74,
            "column": 60
          }
        }
      })) + "</span>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.gc_content_type", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(24, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 75,
            "column": 6
          },
          "end": {
            "line": 79,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "\n      " + alias3((lookupProperty(helpers, "discourse-tags") || depth0 && lookupProperty(depth0, "discourse-tags") || alias2).call(alias1, "topic", {
        "name": "discourse-tags",
        "hash": {
          "mode": "list"
        },
        "hashTypes": {
          "mode": "StringLiteral"
        },
        "hashContexts": {
          "mode": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 81,
            "column": 6
          },
          "end": {
            "line": 81,
            "column": 42
          }
        }
      })) + "\n    </div>\n  ";
    },
    "24": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <div class=\"gc-content-type\">\n          <a href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "contentTypeSearchLink", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 77,
            "column": 19
          },
          "end": {
            "line": 77,
            "column": 44
          }
        }
      })) + "\">" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.gc_content_type", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 77,
            "column": 46
          },
          "end": {
            "line": 77,
            "column": 71
          }
        }
      })) + "</a>\n        </div>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return ((stack1 = lookupProperty(helpers, "if").call(alias1, "bulkSelectEnabled", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.gc-byline", {
        "name": "raw",
        "hash": {
          "relativeCreatedAt": "relativeCreatedAt",
          "isAmazonian": "isAmazonian",
          "avatarFlair": "avatarFlair",
          "topic": "topic"
        },
        "hashTypes": {
          "relativeCreatedAt": "PathExpression",
          "isAmazonian": "PathExpression",
          "avatarFlair": "PathExpression",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "relativeCreatedAt": depth0,
          "isAmazonian": depth0,
          "avatarFlair": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 120
          }
        }
      })) + "\n\n<div class='main-link'>\n  <div class='top-row'>\n    " + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.topic-title", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 13,
            "column": 4
          },
          "end": {
            "line": 13,
            "column": 42
          }
        }
      })) + "\n\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.escapedExcerpt", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 15,
            "column": 4
          },
          "end": {
            "line": 17,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "webinar", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(5, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 19,
            "column": 4
          },
          "end": {
            "line": 30,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </div>\n</div>\n\n<div class=\"topic-meta\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "showAGCPosters", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(7, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 35,
            "column": 2
          },
          "end": {
            "line": 48,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.category", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(13, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 49,
            "column": 2
          },
          "end": {
            "line": 53,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, "displayCategoryExpertFields", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(15, data, 0),
        "inverse": container.program(22, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 54,
            "column": 2
          },
          "end": {
            "line": 83,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + "\n  " + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "agc-topic-list-actions", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 85,
            "column": 2
          },
          "end": {
            "line": 85,
            "column": 46
          }
        }
      })) + "\n</div>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/list/topic-list-item", template, {
    core: false,
    pluginName: "discourse-global-communities",
    hasModernReplacement: false
  });
  var _default = _exports.default = template;
});
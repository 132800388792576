define("discourse/plugins/discourse-global-communities/discourse/connectors/full-page-search-filters/search-advanced-options", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchAdvancedOptions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    displaySearchAdvancedOptions = this._shouldRender();
    displayAiSearch = this.currentUser.agcFeatureFlags?.agc_ai_search_enabled;
    _shouldRender() {
      return this.siteSettings.enable_improved_search;
    }
  }
  _exports.default = SearchAdvancedOptions;
});